import React, { useState, useEffect } from 'react';
import {
    DataTable,
    DataTableSkeleton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Button,
    TextInput,
    DatePicker,
    DatePickerInput,
    Modal,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Pagination,
    Dropdown,
} from '@carbon/react';
import './CSS/Admin.css';
import { useAuth } from '../../AuthContext';

const PaketiAdmin = () => {
    const { pb, currentUserId, isAuthenticated } = useAuth();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [tehnologijaOptions, setTehnologijaOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentRecordId, setCurrentRecordId] = useState(null);
    const [formValues, setFormValues] = useState({
        paket: '',
        opis: '',
        cena: '',
        velja_od: '',
        velja_do: '',
        tip: '',
        tehnologija: '',
    });
    const [filteredData, setFilteredData] = useState([]);

    const packageTypeOptions = [
        { id: 'fixed', name: 'Fiksni paket' },
        { id: 'mobile', name: 'Mobilni paket' },
        { id: 'additional', name: 'Dodatne storitve' },
        { id: 'opremafix', name: 'Dodatna oprema' },
    ];

    // Fetch tehnologija options for dropdown
    useEffect(() => {
        const fetchTehnologijaOptions = async () => {
            try {
                const resultList = await pb.collection('tehnologije').getFullList({ sort: '-created' });
                setTehnologijaOptions(resultList.map((tech) => ({
                    id: tech.id,
                    name: tech.tehnologija, 
                })));
            } catch (error) {
                console.error('Failed to fetch tehnologija options:', error);
            }
        };

        fetchTehnologijaOptions();
    }, [pb]);

    const fetchData = async () => {
        setLoading(true);
        try {
            const resultList = await pb.collection('paketi').getList(page, perPage, {
                sort: '-created',
                expand: 'tehnologija', 
            });
            setData(resultList.items);
            setFilteredData(resultList.items);
            setTotalItems(resultList.totalItems);
        } catch (error) {
            console.error('Failed to fetch paketi data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, perPage]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSearchChange = async (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setLoading(true);

        try {
            const filterQuery = `
                paket ~ "${searchTerm}" ||
                opis ~ "${searchTerm}" ||
                tip ~ "${searchTerm}"
            `;
            const resultList = await pb.collection('paketi').getList(1, perPage, {
                filter: filterQuery,
                sort: '-created',
                expand: 'tehnologija',
            });

            if (resultList && resultList.items) {
                setFilteredData(resultList.items);
                setTotalItems(resultList.totalItems);
                setPage(1); 
            } else {
                setFilteredData([]);
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            setFilteredData([]);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleInputChange = (field, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const handleFormSubmit = async () => {
        try {
            const dataToSubmit = {
                paket: formValues.paket,
                opis: formValues.opis,
                cena: parseFloat(formValues.cena),
                velja_od: formValues.velja_od,
                velja_do: formValues.velja_do,
                tip: formValues.tip,
                tehnologija: formValues.tehnologija,
            };

            if (isEditMode) {
                await pb.collection('paketi').update(currentRecordId, dataToSubmit);
            } else {
                await pb.collection('paketi').create(dataToSubmit);
            }
            setIsModalOpen(false);
            setIsEditMode(false);
            fetchData();
        } catch (error) {
            console.error('Failed to submit form:', error);
        }
    };

    const handleAddNewRecord = () => {
        setFormValues({
            paket: '',
            opis: '',
            cena: '',
            velja_od: '',
            velja_do: '',
            tip: '',
            tehnologija: '',
        });
        setIsEditMode(false);
        setIsModalOpen(true);
    };

    const handleEditRecord = (record) => {
        setFormValues({
            paket: record.paket,
            opis: record.opis,
            cena: record.cena,
            velja_od: formatDate(record.velja_od),
            velja_do: formatDate(record.velja_do),
            tip: record.tip,
            tehnologija: record.tehnologija,
        });
        setCurrentRecordId(record.id);
        setIsEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteRecord = async (recordId) => {
        if (window.confirm('Ali ste prepričani, da želite izbrisati ta paket?')) {
            try {
                await pb.collection('paketi').delete(recordId);
                fetchData();
            } catch (error) {
                console.error('Failed to delete record:', error);
            }
        }
    };

    return (
        <DataTable
            rows={filteredData.map((record) => ({
                id: record.id,
                paket: record.paket,
                opis: record.opis,
                cena: record.cena,
                velja_od: formatDate(record.velja_od),
                velja_do: formatDate(record.velja_do),
                tip: record.tip,
                tehnologija: record.expand?.tehnologija?.tehnologija || '',
                actions: (
                    <div>
                        <Button
                            onClick={() => handleEditRecord(record)}
                            size="small"
                            kind="ghost"
                        >
                            Uredi
                        </Button>
                        <Button
                            onClick={() => handleDeleteRecord(record.id)}
                            size="small"
                            kind="ghost"
                            className="delete-button"
                        >
                            Izbriši
                        </Button>
                    </div>
                ),
            }))}
            headers={[
                { key: 'paket', header: 'Paket' },
                { key: 'opis', header: 'Opis' },
                { key: 'cena', header: 'Cena (€)' },
                { key: 'velja_od', header: 'Velja od' },
                { key: 'velja_do', header: 'Velja do' },
                { key: 'tip', header: 'Tip' },
                { key: 'tehnologija', header: 'Tehnologija' },
                { key: 'actions', header: 'Akcije' },
            ]}
        >
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getToolbarProps,
                getTableContainerProps,
            }) => (
                <TableContainer
                    title="Seznam paketov"
                    description="Upravljajte svoje pakete tukaj"
                    {...getTableContainerProps()}
                >
                    <TableToolbar {...getToolbarProps()}>
                        <TableToolbarContent>
                            <TableToolbarSearch onChange={handleSearchChange} />
                            <Button onClick={handleAddNewRecord}>Dodaj paket</Button>
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })} key={header.key}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow {...getRowProps({ row })} key={row.id}>
                                    {row.cells.map((cell) => (
                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        page={page}
                        pageSize={perPage}
                        pageSizes={[10, 20, 30, 50]}
                        totalItems={totalItems}
                        pageRangeText={(_current, total) => `od ${total} ${total === 1 ? 'stran' : 'strani'}`}
                        itemRangeText={(min, max, total) => `${min}–${max} od ${total} zapisov`}
                        itemsPerPageText="Zapisov na stran"
                        backwardText="Prejšnja stran"
                        forwardText="Naslednja stran"
                        onChange={({ page, pageSize }) => {
                            handlePageChange(page);
                            handlePerPageChange(pageSize);
                        }}
                    />

                    <Modal
                        open={isModalOpen}
                        modalHeading={isEditMode ? "Uredi paket" : "Dodaj nov paket"}
                        primaryButtonText={isEditMode ? "Posodobi paket" : "Dodaj paket"}
                        secondaryButtonText="Prekliči"
                        onRequestClose={() => setIsModalOpen(false)}
                        onRequestSubmit={handleFormSubmit}
                        size="lg"
                    >
                        <TextInput
                            id="paket"
                            labelText="Paket"
                            value={formValues.paket}
                            onChange={(e) => handleInputChange('paket', e.target.value)}
                            required
                        />
                        <TextInput
                            id="opis"
                            labelText="Opis"
                            value={formValues.opis}
                            onChange={(e) => handleInputChange('opis', e.target.value)}
                            required
                        />
                        <TextInput
                            id="cena"
                            labelText="Cena (€)"
                            type="number"
                            value={formValues.cena}
                            onChange={(e) => handleInputChange('cena', e.target.value)}
                            required
                        />
                        <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="single"
                            onChange={(dates) => handleInputChange('velja_od', dates[0] ? dates[0].toISOString() : '')}
                        >
                            <DatePickerInput
                                id="velja_od"
                                placeholder="yyyy-mm-dd"
                                labelText="Velja od"
                                value={formValues.velja_od}
                                required
                            />
                        </DatePicker>
                        <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="single"
                            onChange={(dates) => handleInputChange('velja_do', dates[0] ? dates[0].toISOString() : '')}
                        >
                            <DatePickerInput
                                id="velja_do"
                                placeholder="yyyy-mm-dd"
                                labelText="Velja do"
                                value={formValues.velja_do}
                                required
                            />
                        </DatePicker>
                        <Dropdown
                            id="tip"
                            titleText="Tip"
                            label="Izberite tip paketa"
                            items={packageTypeOptions}
                            itemToString={(item) => (item ? item.name : '')}
                            selectedItem={packageTypeOptions.find(item => item.id === formValues.tip) || null}
                            onChange={({ selectedItem }) => handleInputChange('tip', selectedItem ? selectedItem.id : '')}
                            required
                        />
                        <Dropdown
                            id="tehnologija"
                            titleText="Tehnologija"
                            label="Izberite tehnologijo"
                            items={tehnologijaOptions}
                            itemToString={(item) => (item ? item.name : '')}
                            selectedItem={tehnologijaOptions.find(item => item.id === formValues.tehnologija) || null}
                            onChange={({ selectedItem }) => handleInputChange('tehnologija', selectedItem ? selectedItem.id : '')}
                            required
                        />
                    </Modal>
                </TableContainer>
            )}
        </DataTable>
    );
};

export default PaketiAdmin;