import React, { useState, useEffect } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  Pagination,
  Loading,
  Modal,
  Button,
  TextInput,
  Checkbox,
  Dropdown,
} from '@carbon/react';
import { useAuth } from '../../AuthContext';
import PocketBase from 'pocketbase';
import './CSS/Admin.css'; // Ensure you have the necessary styles here

const OrdersAdmin = () => {
  const { pb } = useAuth();
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;

  // State for edit modal
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editOrderData, setEditOrderData] = useState(null);

  // State for relation dropdowns
  const [tereni, setTereni] = useState([]);
  const [obiski, setObiski] = useState([]);
  const [stranke, setStranke] = useState([]);
  const [tehnologije, setTehnologije] = useState([]);
  const [paketRel, setPaketRel] = useState([]);
  const [akcijaRel, setAkcijaRel] = useState([]);
  const [agenti, setAgenti] = useState([]);

  // Fetch related records for dropdowns
  const fetchRelatedRecords = async () => {
    try {
      const tereniList = await pb.collection('teren').getFullList({ sort: 'naselje_naziv' });
      setTereni(tereniList.map((ter) => ({ label: ter.naselje_naziv, value: ter.id })));

      const obiskiList = await pb.collection('obisk').getFullList({ sort: 'datum' });
      setObiski(obiskiList.map((obisk) => ({ label: `Obisk ${obisk.id}`, value: obisk.id })));

      const strankeList = await pb.collection('stranka').getFullList({ sort: 'ime' });
      setStranke(strankeList.map((stranka) => ({ label: `${stranka.ime} ${stranka.priimek}`, value: stranka.id })));

      const tehnologijeList = await pb.collection('tehnologija').getFullList({ sort: 'tip' });
      setTehnologije(tehnologijeList.map((tehnologija) => ({ label: tehnologija.tip, value: tehnologija.id })));

      const paketRelList = await pb.collection('paket_rel').getFullList({ sort: 'paket' });
      setPaketRel(paketRelList.map((paket) => ({ label: paket.paket, value: paket.id })));

      const akcijaRelList = await pb.collection('akcija_rel').getFullList({ sort: 'popust' });
      setAkcijaRel(akcijaRelList.map((akcija) => ({ label: akcija.popust, value: akcija.id })));

      const agentiList = await pb.collection('agent').getFullList({ sort: 'name' });
      setAgenti(agentiList.map((agent) => ({ label: agent.name, value: agent.id })));
    } catch (error) {
      console.error('Failed to fetch related records:', error);
    }
  };

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const result = await pb.collection('narocilo').getFullList({
        sort: '-created',
        expand: 'teren,obisk,stranka,tehnologija,paket_rel,akcija_rel,agent',
      });
      setOrders(result);
      setFilteredOrders(result);
    } catch (error) {
      console.error('Failed to fetch orders:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
    fetchRelatedRecords();
  }, []);

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filtered = orders.filter((order) => {
      return Object.values(order).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchValue)
      );
    });
    setFilteredOrders(filtered);
    setCurrentPage(1); // Reset to first page on search
  };

  // Existing handleRowClick for Podrobnosti (Details) modal
  const handleRowClick = (orderId) => {
    const order = orders.find((o) => o.id === orderId);
    if (order) {
      setSelectedOrder(order);
      setModalOpen(true);
    } else {
      console.error('Order not found for ID:', orderId);
    }
  };

  // Handler for editing an order
  const handleEditOrder = (orderId) => {
    const order = orders.find((o) => o.id === orderId);
    if (order) {
      // Clone the order data to avoid direct state mutation
      setEditOrderData({ ...order });
      setIsEditModalOpen(true);
    } else {
      console.error('Order not found for ID:', orderId);
    }
  };

  // Handler for deleting an order
  const handleDeleteOrder = async (orderId) => {
    if (window.confirm('Ali ste prepričani, da želite izbrisati to naročilo?')) {
      try {
        await pb.collection('narocilo').delete(orderId);
        fetchOrders();
      } catch (error) {
        console.error('Failed to delete order:', error);
      }
    }
  };

  const closeDetailsModal = () => {
    setSelectedOrder(null);
    setModalOpen(false);
  };

  const closeEditModal = () => {
    setEditOrderData(null);
    setIsEditModalOpen(false);
  };

  // Handle form input changes for edit modal
  const handleEditInputChange = (field, value) => {
    setEditOrderData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  // Implement the edit submission
  const handleEditSubmit = async () => {
    if (!editOrderData) return;

    // Prepare the data object based on the fields
    const data = {
      eid_stavba: editOrderData.eid_stavba,
      st_stan_ali_dela: editOrderData.st_stan_ali_dela,
      opombe: editOrderData.opombe,
      obstojeci_racun: editOrderData.obstojeci_racun,
      obstojeci_operater: editOrderData.obstojeci_operater,
      obstojeca_oprema: editOrderData.obstojeca_oprema,
      datum_vezave: editOrderData.datum_vezave,
      teren: editOrderData.teren,
      davcna_st: editOrderData.davcna_st,
      ime: editOrderData.ime,
      priimek: editOrderData.priimek,
      ulica: editOrderData.ulica,
      hisna_st: editOrderData.hisna_st,
      postna_st: editOrderData.postna_st,
      posta: editOrderData.posta,
      nadstropje_in_st_stanovanja: editOrderData.nadstropje_in_st_stanovanja,
      st_za_prenos: editOrderData.st_za_prenos,
      kontakt_tel: editOrderData.kontakt_tel,
      email: editOrderData.email,
      prenos_telefonske: editOrderData.prenos_telefonske,
      st_racuna_donorja_ali_puk: editOrderData.st_racuna_donorja_ali_puk,
      operater_donor: editOrderData.operater_donor,
      narocnik: editOrderData.narocnik,
      st_narocnika_ali_uporabnisko_ime: editOrderData.st_narocnika_ali_uporabnisko_ime,
      storitve_za_prekinitev: editOrderData.storitve_za_prekinitev,
      oprema: editOrderData.oprema,
      prikljucnina: editOrderData.prikljucnina,
      is_prikljucnina: editOrderData.is_prikljucnina,
      ugodnost_ob_vezavi: editOrderData.ugodnost_ob_vezavi,
      paketStoritev: editOrderData.paketStoritev,
      dodatne_storitve_interneta: editOrderData.dodatne_storitve_interneta,
      dodatne_storitve_telefonije: editOrderData.dodatne_storitve_telefonije,
      prenos_stevilke_soglasje: editOrderData.prenos_stevilke_soglasje,
      soglasje_za_prekinitev: editOrderData.soglasje_za_prekinitev,
      placilo_s_trajnikom: editOrderData.placilo_s_trajnikom,
      cena_paketa_storitev: editOrderData.cena_paketa_storitev,
      racun_sms: editOrderData.racun_sms,
      racun_email: editOrderData.racun_email,
      racun_banka: editOrderData.racun_banka,
      racun_portal: editOrderData.racun_portal,
      racun_email_naslov: editOrderData.racun_email_naslov,
      racun_banka_iban: editOrderData.racun_banka_iban,
      racun_pisno: editOrderData.racun_pisno,
      racun_pisno_ulica: editOrderData.racun_pisno_ulica,
      racun_pisno_postna_st: editOrderData.racun_pisno_postna_st,
      racun_pisno_mesto: editOrderData.racun_pisno_mesto,
      podjetje: editOrderData.podjetje,
      zavezanec_za_ddv: editOrderData.zavezanec_za_ddv,
      maticna_st_podjetje: editOrderData.maticna_st_podjetje,
      predcasna_aktivacija: editOrderData.predcasna_aktivacija,
      osebni_dokument: editOrderData.osebni_dokument,
      popust_vezava: editOrderData.popust_vezava,
      mobilni_paket: editOrderData.mobilni_paket,
      prenos_ime: editOrderData.prenos_ime,
      prenos_naslov: editOrderData.prenos_naslov,
      prenos_postna_kraj: editOrderData.prenos_postna_kraj,
      prenos_davcna: editOrderData.prenos_davcna,
      prenos_telefonska_st: editOrderData.prenos_telefonska_st,
      prenostel_msisdn1: editOrderData.prenostel_msisdn1,
      prenostel_puk1a: editOrderData.prenostel_puk1a,
      prenostel_msisdn2: editOrderData.prenostel_msisdn2,
      prenostel_puk2a: editOrderData.prenostel_puk2a,
      prenostel_st_racuna: editOrderData.prenostel_st_racuna,
      prenostel_dajalec: editOrderData.prenostel_dajalec,
      prenostel_ime_podjetje: editOrderData.prenostel_ime_podjetje,
      prenostel_naslov: editOrderData.prenostel_naslov,
      prenostel_postna_st: editOrderData.prenostel_postna_st,
      prenostel_kraj: editOrderData.prenostel_kraj,
      prenostel_davcna: editOrderData.prenostel_davcna,
      prenostel_pooblascen: editOrderData.prenostel_pooblascen,
      prenostel_prekinitev_dajalec: editOrderData.prenostel_prekinitev_dajalec,
      prenostel_ohranitev_dajalec: editOrderData.prenostel_ohranitev_dajalec,
      prenostel_ime_narocnika: editOrderData.prenostel_ime_narocnika,
      prenostel_narocniska_st_upor_ime: editOrderData.prenostel_narocniska_st_upor_ime,
      prenostel_prekini_ohrani_sirokopasovni: editOrderData.prenostel_prekini_ohrani_sirokopasovni,
      prenostel_ohrani_tv: editOrderData.prenostel_ohrani_tv,
      prenostel_ohrani_internet: editOrderData.prenostel_ohrani_internet,
      prenostel_ohrani_domeno: editOrderData.prenostel_ohrani_domeno,
      prenostel_domena: editOrderData.prenostel_domena,
      prenostel_ohrani_email: editOrderData.prenostel_ohrani_email,
      prenostel_email: editOrderData.prenostel_email,
      geolatitude: editOrderData.geolatitude,
      geolongitude: editOrderData.geolongitude,
      glatitude: editOrderData.glatitude,
      glongitude: editOrderData.glongitude,
      obisk: editOrderData.obisk,
      naselje: editOrderData.naselje,
      stranka: editOrderData.stranka,
      tehnologija: editOrderData.tehnologija,
      paket_rel: editOrderData.paket_rel,
      akcija_rel: editOrderData.akcija_rel,
      paket: editOrderData.paket,
      popust: editOrderData.popust,
      esim: editOrderData.esim,
      islegal: editOrderData.islegal,
      agent: editOrderData.agent,
    };

    try {
      await pb.collection('narocilo').update(editOrderData.id, data);
      fetchOrders();
      closeEditModal();
      alert('Naročilo je bilo uspešno posodobljeno.');
    } catch (error) {
      console.error('Failed to update order:', error);
      alert('Prišlo je do napake pri posodabljanju naročila.');
    }
  };

  // Pagination Logic
  const totalPages = Math.ceil(filteredOrders.length / rowsPerPage);
  const paginatedOrders = filteredOrders.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  // Define Table Headers
  const headers = [
    { key: 'ime', header: 'Ime' },
    { key: 'priimek', header: 'Priimek' },
    { key: 'email', header: 'E-pošta' },
    { key: 'telefonska_st', header: 'Telefonska številka' },
    { key: 'created', header: 'Datum' },
    { key: 'agent_name', header: 'Ime agenta' },
    { key: 'actions', header: 'Akcije' }, // Updated header to 'Akcije'
  ];

  return (
    <div className="orders-list-container" style={{ padding: '20px' }}>
      <h1>Seznam naročil</h1>
      <TableToolbar>
        <TableToolbarContent>
          <TableToolbarSearch
            id="order-search"
            placeholder="Išči po imenu, priimku ali e-pošti..."
            value={searchTerm}
            onChange={handleSearchChange}
            labelText="Iskanje naročil"
          />
        </TableToolbarContent>
      </TableToolbar>
      {loading ? (
        <Loading description="Nalagam naročila..." withOverlay={false} />
      ) : (
        <>
          {filteredOrders.length === 0 ? (
            <p>Ni naročil, ki bi ustrezala iskalnemu kriteriju.</p>
          ) : (
            <>
              <DataTable
                rows={paginatedOrders.map((order) => ({
                  id: order.id, // Keep the ID for internal purposes
                  ime: order.ime,
                  priimek: order.priimek,
                  email: order.email,
                  telefonska_st: order.kontakt_tel || 'Ni na voljo',
                  created: new Date(order.created).toLocaleDateString('sl-SI'),
                  agent_name: order.expand?.agent?.name || 'Ni na voljo',
                  actions: (
                    <div className="actions-container">
                      <Button
                        size="small"
                        kind="ghost"
                        onClick={() => handleRowClick(order.id)}
                      >
                        Podrobnosti
                      </Button>
                      <Button
                        size="small"
                        kind="ghost"
                        onClick={() => handleEditOrder(order.id)}
                      >
                        Uredi
                      </Button>
                      <Button
                        size="small"
                        kind="ghost"
                        onClick={() => handleDeleteOrder(order.id)}
                        className="delete-button"
                      >
                        Izbriši
                      </Button>
                    </div>
                  ),
                }))}
                headers={headers}
                render={({
                  rows,
                  headers,
                  getHeaderProps,
                  getRowProps,
                  getTableProps,
                  getToolbarProps,
                  getTableContainerProps,
                }) => (
                  <TableContainer title="Naročila" {...getTableContainerProps()}>
                    <Table {...getTableProps()}>
                      <TableHead>
                        <TableRow>
                          {headers.map((header) => (
                            <TableHeader
                              {...getHeaderProps({ header })}
                              key={header.key}
                            >
                              {header.header}
                            </TableHeader>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow {...getRowProps({ row })} key={row.id}>
                            {row.cells.map((cell) => (
                              <TableCell key={cell.id}>{cell.value}</TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              />
              <Pagination
                totalItems={filteredOrders.length}
                backwardText="Prejšnja stran"
                forwardText="Naslednja stran"
                page={currentPage}
                pageNumberText="Stran"
                itemsPerPageText="Vrstic na stran"
                onChange={({ page }) => setCurrentPage(page)}
                pageSizes={[10, 20, 30]}
                onChangeItemsPerPage={() => {}}
                pageSize={rowsPerPage}
              />
            </>
          )}
        </>
      )}

      {/* Modal for Order Details */}
      {selectedOrder && (
        <Modal
          open={modalOpen}
          modalHeading={`Naročilo ID: ${selectedOrder.id}`}
          primaryButtonText="Zapri"
          onRequestClose={closeDetailsModal}
          onRequestSubmit={closeDetailsModal}
          size="lg"
        >
          {/* Order details content */}
          <div className="order-details">
            <h2>Podatki o naročniku</h2>
            <p><strong>Ime:</strong> {selectedOrder.ime}</p>
            <p><strong>Priimek:</strong> {selectedOrder.priimek}</p>
            <p><strong>Zakoniti zastopnik družbe:</strong> {selectedOrder.zakonitiZastopnik || 'Ni na voljo'}</p>
            <p><strong>Matična številka:</strong> {selectedOrder.maticna_st_podjetje || 'Ni na voljo'}</p>
            <p><strong>Zavezanec za DDV:</strong> {selectedOrder.zavezanec_za_ddv ? 'Da' : 'Ne'}</p>
            <p><strong>Ulica, hišna številka:</strong> {selectedOrder.ulica}, {selectedOrder.hisna_st}</p>
            <p><strong>Poštna številka:</strong> {selectedOrder.postna_st}</p>
            <p><strong>Pošta:</strong> {selectedOrder.posta}</p>
            <p><strong>Nadstropje in št. stanovanja:</strong> {selectedOrder.nadstropje_in_st_stanovanja || 'Ni na voljo'}</p>
            <p><strong>Davčna številka:</strong> {selectedOrder.davcna_st || 'Ni na voljo'}</p>
            <p><strong>Kontaktni telefon:</strong> {selectedOrder.kontakt_tel || 'Ni na voljo'}</p>
            <p><strong>E-poštni naslov:</strong> {selectedOrder.email}</p>
            <p><strong>Opombe:</strong> {selectedOrder.opombe || 'Ni na voljo'}</p>

            <h2>Priključek in storitve</h2>
            <p><strong>Tip paketa:</strong> {selectedOrder.mobilni_paket ? 'Mobilni paket' : 'Fiksni paket'}</p>
            <p><strong>Zahtevek za prenos številke:</strong> {selectedOrder.prenos_telefonske ? 'Da' : 'Ne'}</p>
            <p><strong>Tehnologija:</strong> {selectedOrder.tehnologija || 'Ni na voljo'}</p>
            <p><strong>Paket storitev:</strong> {selectedOrder.paketStoritev || 'Ni na voljo'}</p>
            <p><strong>Akcija:</strong> {selectedOrder.akcija_rel || 'Ni na voljo'}</p>
            <p><strong>Dodatni paketi in oprema:</strong> {selectedOrder.dodatne_storitve_interneta || 'Ni na voljo'}</p>

            <h2>Prenos številke</h2>
            <p><strong>Telefonska številka za prenos:</strong> {selectedOrder.prenos_telefonska_st || 'Ni na voljo'}</p>
            <p><strong>Operater (darovalec):</strong> {selectedOrder.operater_donor || 'Ni na voljo'}</p>
            <p><strong>Številka računa pri operaterju:</strong> {selectedOrder.st_racuna_donorja_ali_puk || 'Ni na voljo'}</p>
            <p><strong>Naročnik je druga oseba:</strong> {selectedOrder.narocnik ? 'Da' : 'Ne'}</p>
            {selectedOrder.narocnik && (
              <>
                <p><strong>Ime in priimek/naziv podjetja:</strong> {selectedOrder.prenos_ime || 'Ni na voljo'}</p>
                <p><strong>Naslov stalnega bivališča/sedež podjetja:</strong> {selectedOrder.prenos_naslov || 'Ni na voljo'}</p>
                <p><strong>Poštna št.:</strong> {selectedOrder.prenos_postna_kraj || 'Ni na voljo'}</p>
                <p><strong>Kraj:</strong> {selectedOrder.prenos_kraj || 'Ni na voljo'}</p>
                <p><strong>Davčna številka:</strong> {selectedOrder.prenos_davcna || 'Ni na voljo'}</p>
              </>
            )}

            <h2>Računi</h2>
            <p><strong>E-poštni račun:</strong> {selectedOrder.racun_email ? 'Da' : 'Ne'}</p>
            {selectedOrder.racun_email && (
              <p><strong>E-poštni naslov za račun:</strong> {selectedOrder.racun_email_naslov || 'Ni na voljo'}</p>
            )}
            <p><strong>SMS račun:</strong> {selectedOrder.racun_sms ? 'Da' : 'Ne'}</p>
            <p><strong>Račun po pošti:</strong> {selectedOrder.racun_pisno ? 'Da' : 'Ne'}</p>
            {selectedOrder.racun_pisno && (
              <>
                <p><strong>Ulica, hišna številka:</strong> {selectedOrder.racun_pisno_ulica}, {selectedOrder.racun_pisno_mesto}</p>
                <p><strong>Poštna št.:</strong> {selectedOrder.racun_pisno_postna_st || 'Ni na voljo'}</p>
                <p><strong>Mesto:</strong> {selectedOrder.racun_pisno_mesto || 'Ni na voljo'}</p>
              </>
            )}

            <h2>Priloge</h2>
            {selectedOrder.priloge && selectedOrder.priloge.length > 0 ? (
              selectedOrder.priloge.map((attachment, index) => (
                <p key={index}><strong>Dokument:</strong> {attachment}</p>
              ))
            ) : (
              <p>Ni priloženih dokumentov</p>
            )}

            <h2>GDPR</h2>
            <p><strong>Soglasje za obdelavo osebnih podatkov:</strong> {selectedOrder.gdpr_consent ? 'Da' : 'Ne'}</p>
            <p><strong>Soglasje za prejemanje marketinških sporočil:</strong> {selectedOrder.gdpr_marketing ? 'Da' : 'Ne'}</p>

            <h2>Teren</h2>
            <p><strong>Naselje:</strong> {selectedOrder.expand?.teren?.naselje_naziv || 'Ni na voljo'}</p>
            <p><strong>Ulica:</strong> {selectedOrder.expand?.teren?.ulica_naziv || 'Ni na voljo'}</p>
            <p><strong>Št. gospodinjstev:</strong> {selectedOrder.expand?.teren?.st_gospodinjstev || 'Ni na voljo'}</p>

            <h2>Obiski</h2>
            <p><strong>Datum obiska:</strong> {selectedOrder.expand?.obisk?.datum || 'Ni na voljo'}</p>
            <p><strong>Odziv:</strong> {selectedOrder.expand?.obisk?.odziv || 'Ni na voljo'}</p>
            <p><strong>Opombe:</strong> {selectedOrder.expand?.obisk?.opombe || 'Ni na voljo'}</p>

            <h2>Stranka</h2>
            <p><strong>Ime:</strong> {selectedOrder.expand?.stranka?.ime || 'Ni na voljo'}</p>
            <p><strong>Priimek:</strong> {selectedOrder.expand?.stranka?.priimek || 'Ni na voljo'}</p>
            <p><strong>Podjetje:</strong> {selectedOrder.expand?.stranka?.podjetje || 'Ni na voljo'}</p>
            <p><strong>E-pošta:</strong> {selectedOrder.expand?.stranka?.email || 'Ni na voljo'}</p>

            <h2>Tehnologija</h2>
            <p><strong>Tip tehnologije:</strong> {selectedOrder.expand?.tehnologija?.tip || 'Ni na voljo'}</p>
            <p><strong>Tehnologija:</strong> {selectedOrder.expand?.tehnologija?.tehnologija || 'Ni na voljo'}</p>

            <h2>Paket in Akcija</h2>
            <p><strong>Paket:</strong> {selectedOrder.expand?.paket_rel?.paket || 'Ni na voljo'}</p>
            <p><strong>Opis paketa:</strong> {selectedOrder.expand?.paket_rel?.opis || 'Ni na voljo'}</p>
            <p><strong>Akcija:</strong> {selectedOrder.expand?.akcija_rel?.popust || 'Ni na voljo'}</p>

            <h2>Agent</h2>
            <p><strong>Ime agenta:</strong> {selectedOrder.expand?.agent?.name || 'Ni na voljo'}</p>
            <p><strong>Vloga:</strong> {selectedOrder.expand?.agent?.role || 'Ni na voljo'}</p>
          </div>
        </Modal>
      )}

      {/* Modal for Editing Order */}
      {editOrderData && (
        <Modal
          open={isEditModalOpen}
          modalHeading={`Uredi Naročilo ID: ${editOrderData.id}`}
          primaryButtonText="Posodobi naročilo"
          secondaryButtonText="Prekliči"
          onRequestClose={closeEditModal}
          onRequestSubmit={handleEditSubmit}
          size="lg"
        >
          {/* Form fields for editing order */}
          <div className="edit-order-form">
            {/* Podatki o naročniku */}
            <h2>Podatki o naročniku</h2>
            <TextInput
              id="ime-edit"
              labelText="Ime"
              value={editOrderData.ime}
              onChange={(e) => handleEditInputChange('ime', e.target.value)}
            />
            <TextInput
              id="priimek-edit"
              labelText="Priimek"
              value={editOrderData.priimek}
              onChange={(e) => handleEditInputChange('priimek', e.target.value)}
            />
            <TextInput
              id="zakonitiZastopnik-edit"
              labelText="Zakoniti zastopnik družbe"
              value={editOrderData.zakonitiZastopnik || ''}
              onChange={(e) => handleEditInputChange('zakonitiZastopnik', e.target.value)}
            />
            <TextInput
              id="maticnaStPodjetje-edit"
              labelText="Matična številka podjetja"
              value={editOrderData.maticna_st_podjetje || ''}
              onChange={(e) => handleEditInputChange('maticna_st_podjetje', e.target.value)}
            />
            <Checkbox
              labelText="Zavezanec za DDV"
              id="zavezanecZaDDV-edit"
              checked={editOrderData.zavezanec_za_ddv}
              onChange={(checked) => handleEditInputChange('zavezanec_za_ddv', checked)}
            />
            <TextInput
              id="ulica-edit"
              labelText="Ulica"
              value={editOrderData.ulica}
              onChange={(e) => handleEditInputChange('ulica', e.target.value)}
            />
            <TextInput
              id="hisnaSt-edit"
              labelText="Hišna številka"
              value={editOrderData.hisna_st}
              onChange={(e) => handleEditInputChange('hisna_st', e.target.value)}
            />
            <TextInput
              id="postnaSt-edit"
              labelText="Poštna številka"
              value={editOrderData.postna_st}
              onChange={(e) => handleEditInputChange('postna_st', e.target.value)}
            />
            <TextInput
              id="posta-edit"
              labelText="Pošta"
              value={editOrderData.posta}
              onChange={(e) => handleEditInputChange('posta', e.target.value)}
            />
            <TextInput
              id="nadstropjeInStStanovanja-edit"
              labelText="Nadstropje in št. stanovanja"
              value={editOrderData.nadstropje_in_st_stanovanja || ''}
              onChange={(e) => handleEditInputChange('nadstropje_in_st_stanovanja', e.target.value)}
            />
            <TextInput
              id="davcnaSt-edit"
              labelText="Davčna številka"
              value={editOrderData.davcna_st || ''}
              onChange={(e) => handleEditInputChange('davcna_st', e.target.value)}
            />
            <TextInput
              id="kontaktTel-edit"
              labelText="Kontaktni telefon"
              value={editOrderData.kontakt_tel || ''}
              onChange={(e) => handleEditInputChange('kontakt_tel', e.target.value)}
            />
            <TextInput
              id="email-edit"
              labelText="E-poštni naslov"
              value={editOrderData.email}
              onChange={(e) => handleEditInputChange('email', e.target.value)}
            />
            <TextInput
              id="opombe-edit"
              labelText="Opombe"
              value={editOrderData.opombe || ''}
              onChange={(e) => handleEditInputChange('opombe', e.target.value)}
            />

            {/* Priključek in storitve */}
            <h2>Priključek in storitve</h2>
            <Dropdown
              id="tipPaketa-edit"
              titleText="Tip paketa"
              label="Izberite tip paketa"
              items={[
                { label: 'Mobilni paket', value: 'Mobilni paket' },
                { label: 'Fiksni paket', value: 'Fiksni paket' },
              ]}
              selectedItem={{ label: editOrderData.mobilni_paket ? 'Mobilni paket' : 'Fiksni paket', value: editOrderData.mobilni_paket ? 'Mobilni paket' : 'Fiksni paket' }}
              onChange={({ selectedItem }) => handleEditInputChange('mobilni_paket', selectedItem.value === 'Mobilni paket')}
            />
            <Checkbox
              labelText="Zahtevek za prenos številke"
              id="prenosTelefonske-edit"
              checked={editOrderData.prenos_telefonske}
              onChange={(checked) => handleEditInputChange('prenos_telefonske', checked)}
            />
            <Dropdown
              id="tehnologija-edit"
              titleText="Tehnologija"
              label="Izberite tehnologijo"
              items={tehnologije}
              selectedItem={tehnologije.find((tehnologija) => tehnologija.value === editOrderData.tehnologija) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('tehnologija', selectedItem.value)}
            />
            <Dropdown
              id="paketStoritev-edit"
              titleText="Paket storitev"
              label="Izberite paket storitev"
              items={paketRel}
              selectedItem={paketRel.find((paket) => paket.value === editOrderData.paket_rel) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('paket_rel', selectedItem.value)}
            />
            <Dropdown
              id="akcija-edit"
              titleText="Akcija"
              label="Izberite akcijo"
              items={akcijaRel}
              selectedItem={akcijaRel.find((akcija) => akcija.value === editOrderData.akcija_rel) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('akcija_rel', selectedItem.value)}
            />
            <TextInput
              id="dodatneStoritveInterneta-edit"
              labelText="Dodatni paketi in oprema"
              value={editOrderData.dodatne_storitve_interneta || ''}
              onChange={(e) => handleEditInputChange('dodatne_storitve_interneta', e.target.value)}
            />

            {/* Prenos številke */}
            <h2>Prenos številke</h2>
            <TextInput
              id="prenosTelefonskaSt-edit"
              labelText="Telefonska številka za prenos"
              value={editOrderData.prenos_telefonska_st || ''}
              onChange={(e) => handleEditInputChange('prenos_telefonska_st', e.target.value)}
            />
            <TextInput
              id="operaterDonor-edit"
              labelText="Operater (darovalec)"
              value={editOrderData.operater_donor || ''}
              onChange={(e) => handleEditInputChange('operater_donor', e.target.value)}
            />
            <TextInput
              id="stRacunaDonorja-edit"
              labelText="Številka računa pri operaterju"
              value={editOrderData.st_racuna_donorja_ali_puk || ''}
              onChange={(e) => handleEditInputChange('st_racuna_donorja_ali_puk', e.target.value)}
            />
            <Checkbox
              labelText="Naročnik je druga oseba"
              id="narocnik-edit"
              checked={editOrderData.narocnik === 'test' ? true : false} // Adjust based on actual data type
              onChange={(checked) => handleEditInputChange('narocnik', checked ? 'test' : 'ne')}
            />
            {editOrderData.narocnik && (
              <>
                <TextInput
                  id="prenosIme-edit"
                  labelText="Ime in priimek/naziv podjetja"
                  value={editOrderData.prenos_ime || ''}
                  onChange={(e) => handleEditInputChange('prenos_ime', e.target.value)}
                />
                <TextInput
                  id="prenosNaslov-edit"
                  labelText="Naslov stalnega bivališča/sedež podjetja"
                  value={editOrderData.prenos_naslov || ''}
                  onChange={(e) => handleEditInputChange('prenos_naslov', e.target.value)}
                />
                <TextInput
                  id="prenosPostnaKraj-edit"
                  labelText="Poštna št."
                  value={editOrderData.prenos_postna_kraj || ''}
                  onChange={(e) => handleEditInputChange('prenos_postna_kraj', e.target.value)}
                />
                <TextInput
                  id="prenosKraj-edit"
                  labelText="Kraj"
                  value={editOrderData.prenos_kraj || ''}
                  onChange={(e) => handleEditInputChange('prenos_kraj', e.target.value)}
                />
                <TextInput
                  id="prenosDavcna-edit"
                  labelText="Davčna številka"
                  value={editOrderData.prenos_davcna || ''}
                  onChange={(e) => handleEditInputChange('prenos_davcna', e.target.value)}
                />
              </>
            )}

            {/* Računi */}
            <h2>Računi</h2>
            <Checkbox
              labelText="E-poštni račun"
              id="racunEmail-edit"
              checked={editOrderData.racun_email}
              onChange={(checked) => handleEditInputChange('racun_email', checked)}
            />
            {editOrderData.racun_email && (
              <TextInput
                id="racunEmailNaslov-edit"
                labelText="E-poštni naslov za račun"
                value={editOrderData.racun_email_naslov || ''}
                onChange={(e) => handleEditInputChange('racun_email_naslov', e.target.value)}
              />
            )}
            <Checkbox
              labelText="SMS račun"
              id="racunSms-edit"
              checked={editOrderData.racun_sms}
              onChange={(checked) => handleEditInputChange('racun_sms', checked)}
            />
            <Checkbox
              labelText="Račun po pošti"
              id="racunPisno-edit"
              checked={editOrderData.racun_pisno}
              onChange={(checked) => handleEditInputChange('racun_pisno', checked)}
            />
            {editOrderData.racun_pisno && (
              <>
                <TextInput
                  id="racunPisnoUlica-edit"
                  labelText="Ulica, hišna številka"
                  value={editOrderData.racun_pisno_ulica || ''}
                  onChange={(e) => handleEditInputChange('racun_pisno_ulica', e.target.value)}
                />
                <TextInput
                  id="racunPisnoPostnaSt-edit"
                  labelText="Poštna št."
                  value={editOrderData.racun_pisno_postna_st || ''}
                  onChange={(e) => handleEditInputChange('racun_pisno_postna_st', e.target.value)}
                />
                <TextInput
                  id="racunPisnoMesto-edit"
                  labelText="Mesto"
                  value={editOrderData.racun_pisno_mesto || ''}
                  onChange={(e) => handleEditInputChange('racun_pisno_mesto', e.target.value)}
                />
              </>
            )}

            {/* Priloge */}
            <h2>Priloge</h2>
            <TextInput
              id="priloge-edit"
              labelText="Priloge (dodatni dokumenti)"
              value={editOrderData.priloge ? editOrderData.priloge.join(', ') : ''}
              onChange={(e) => handleEditInputChange('priloge', e.target.value.split(',').map(item => item.trim()))}
            />

            {/* GDPR */}
            <h2>GDPR</h2>
            <Checkbox
              labelText="Soglasje za obdelavo osebnih podatkov"
              id="gdprConsent-edit"
              checked={editOrderData.gdpr_consent}
              onChange={(checked) => handleEditInputChange('gdpr_consent', checked)}
            />
            <Checkbox
              labelText="Soglasje za prejemanje marketinških sporočil"
              id="gdprMarketing-edit"
              checked={editOrderData.gdpr_marketing}
              onChange={(checked) => handleEditInputChange('gdpr_marketing', checked)}
            />

            {/* Teren */}
            <h2>Teren</h2>
            <Dropdown
              id="teren-edit"
              titleText="Teren"
              label="Izberite teren"
              items={tereni}
              selectedItem={tereni.find((teren) => teren.value === editOrderData.teren) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('teren', selectedItem.value)}
            />
            <TextInput
              id="naselje-edit"
              labelText="Naselje"
              value={editOrderData.naselje || ''}
              onChange={(e) => handleEditInputChange('naselje', e.target.value)}
            />
            <TextInput
              id="stGospodinjstev-edit"
              labelText="Št. gospodinjstev"
              value={editOrderData.st_gospodinjstev || ''}
              onChange={(e) => handleEditInputChange('st_gospodinjstev', e.target.value)}
            />

            {/* Obiski */}
            <h2>Obiski</h2>
            <Dropdown
              id="obisk-edit"
              titleText="Obisk"
              label="Izberite obisk"
              items={obiski}
              selectedItem={obiski.find((obisk) => obisk.value === editOrderData.obisk) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('obisk', selectedItem.value)}
            />
            <TextInput
              id="datumObiska-edit"
              labelText="Datum obiska"
              type="datetime-local"
              value={editOrderData.expand?.obisk?.datum ? new Date(editOrderData.expand.obisk.datum).toISOString().slice(0,16) : ''}
              onChange={(e) => handleEditInputChange('obisk_datum', e.target.value)}
            />
            <TextInput
              id="odziv-edit"
              labelText="Odziv"
              value={editOrderData.expand?.obisk?.odziv || ''}
              onChange={(e) => handleEditInputChange('obisk_odziv', e.target.value)}
            />
            <TextInput
              id="opombeObisk-edit"
              labelText="Opombe za obisk"
              value={editOrderData.expand?.obisk?.opombe || ''}
              onChange={(e) => handleEditInputChange('obisk_opombe', e.target.value)}
            />

            {/* Stranka */}
            <h2>Stranka</h2>
            <Dropdown
              id="stranka-edit"
              titleText="Stranka"
              label="Izberite stranko"
              items={stranke}
              selectedItem={stranke.find((stranka) => stranka.value === editOrderData.stranka) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('stranka', selectedItem.value)}
            />
            <TextInput
              id="strankaIme-edit"
              labelText="Ime stranke"
              value={editOrderData.expand?.stranka?.ime || ''}
              onChange={(e) => handleEditInputChange('stranka_ime', e.target.value)}
            />
            <TextInput
              id="strankaPriimek-edit"
              labelText="Priimek stranke"
              value={editOrderData.expand?.stranka?.priimek || ''}
              onChange={(e) => handleEditInputChange('stranka_priimek', e.target.value)}
            />
            <TextInput
              id="strankaPodjetje-edit"
              labelText="Podjetje"
              value={editOrderData.expand?.stranka?.podjetje || ''}
              onChange={(e) => handleEditInputChange('stranka_podjetje', e.target.value)}
            />
            <TextInput
              id="strankaEmail-edit"
              labelText="E-pošta stranke"
              value={editOrderData.expand?.stranka?.email || ''}
              onChange={(e) => handleEditInputChange('stranka_email', e.target.value)}
            />

            {/* Tehnologija */}
            <h2>Tehnologija</h2>
            <Dropdown
              id="tehnologijaType-edit"
              titleText="Tip tehnologije"
              label="Izberite tip tehnologije"
              items={tehnologije}
              selectedItem={tehnologije.find((tehnologija) => tehnologija.value === editOrderData.tehnologija) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('tehnologija', selectedItem.value)}
            />
            <TextInput
              id="tehnologijaTeknologija-edit"
              labelText="Tehnologija"
              value={editOrderData.expand?.tehnologija?.tehnologija || ''}
              onChange={(e) => handleEditInputChange('tehnologija_tehnologija', e.target.value)}
            />

            {/* Paket in Akcija */}
            <h2>Paket in Akcija</h2>
            <Dropdown
              id="paket-edit"
              titleText="Paket"
              label="Izberite paket"
              items={paketRel}
              selectedItem={paketRel.find((paket) => paket.value === editOrderData.paket_rel) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('paket_rel', selectedItem.value)}
            />
            <TextInput
              id="paketOpis-edit"
              labelText="Opis paketa"
              value={editOrderData.expand?.paket_rel?.opis || ''}
              onChange={(e) => handleEditInputChange('paket_rel_opis', e.target.value)}
            />
            <Dropdown
              id="akcija-edit"
              titleText="Akcija"
              label="Izberite akcijo"
              items={akcijaRel}
              selectedItem={akcijaRel.find((akcija) => akcija.value === editOrderData.akcija_rel) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('akcija_rel', selectedItem.value)}
            />
            <TextInput
              id="dodatneStoritveInterneta-edit"
              labelText="Dodatne storitve interneta"
              value={editOrderData.dodatne_storitve_interneta || ''}
              onChange={(e) => handleEditInputChange('dodatne_storitve_interneta', e.target.value)}
            />

            {/* Agent */}
            <h2>Agent</h2>
            <Dropdown
              id="agent-edit"
              titleText="Agent"
              label="Izberite agenta"
              items={agenti}
              selectedItem={agenti.find((agent) => agent.value === editOrderData.agent) || { label: 'Ni na voljo', value: '' }}
              onChange={({ selectedItem }) => handleEditInputChange('agent', selectedItem.value)}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default OrdersAdmin;