import './_sale-page.scss';
import React, { useState } from 'react';
import { Checkbox, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, Form, DataTable } from '@carbon/react';

function GDPRForm({ narociloId, checkboxes, setCheckboxes, gdprChanged, setGdprChanged }) {

    const rows = [
        "neposrednega trženja oziroma obveščanja o ponudbi in ugodnostih za storitve Telemacha in njegovih partnerskih podjetij, ki ne predstavljajo elektronskih komunikacijskih storitev (pri izbrani možnosti SMS pa tudi o teh)",
        "obveščanja o nagradnih igrah, ki jih organizira Telemach ali njegova partnerska podjetja v sodelovanju s Telemachom",
        "anketiranja v zvezi z zadovoljstvom naročnikov in dodatnimi storitvami Telemacha"
    ];

    const communicationMethods = [
        'vseh navedenih načinov komunikacije', 'mobilnega telefona z SMS/MMS', 'e-pošte', 'pošte',
        'telefonskega klica', 'prikaza na digitalnem televizijskem sprejemniku', 'Telemachovih aplikacij'
    ];

    // Map a unique ID to the original checkbox key
    const getCheckboxKey = (uniqueId) => {
        const match = uniqueId.match(/cb_(\d+)_(\d+)/);
        if (match) {
            return `cb_${match[1]}_${match[2]}`;
        }
        return uniqueId;
    };

    const toggleCheckbox = (id) => {
        setCheckboxes(prevState => ({ ...prevState, [id]: !prevState[id] }));
       // if (!gdprChanged) setGdprChanged(true);
    };

    const handleSelectAllForRow = (row) => {
        const newValues = {};
        for (let i = 1; i <= 7; i++) {
            const id = `cb_${row}_${i}`;
            newValues[id] = !checkboxes[`cb_${row}_1`]; // Check the state of the first checkbox to toggle all
        }
        setCheckboxes(prevState => ({ ...prevState, ...newValues }));
       // if (!gdprChanged) setGdprChanged(true);
    };

    const handleGlobalSelectAll = () => {
        const allSelected = !Object.values(checkboxes).some(value => !value);
        const newState = Object.keys(checkboxes).reduce((acc, key) => {
            acc[key] = !allSelected;
            return acc;
        }, {});
        setCheckboxes(newState);
       // if (!gdprChanged) setGdprChanged(true);
    };
/*
    const handleSubmit = (event) => {
        event.preventDefault();
        console.log('Form data to be submitted:', checkboxes);
        // Submit the `checkboxes` state to the server or handle it accordingly
    };
*/
    return (
        <div className="gdpr-form">
            <Form>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <h2>Upravljanje z obsegom obdelave osebnih podatkov</h2>
                    <p>Informacije želim prejemati ali jih s Telemachom deliti z uporabo:</p>
                </div>
                <table className="gdpr-table">
                    <thead>
                        <tr>
                            <th>Za namen:</th>
                            {communicationMethods.map((method, index) => (
                                <th key={index}>{method}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((description, rowIndex) => (
                            <tr key={rowIndex}>
                                <td>{description}</td>
                                <td>
                                    <Checkbox
                                        id={`${narociloId}_selectAllRow${rowIndex + 1}`}
                                        labelText=""
                                        checked={Object.keys(checkboxes).slice(rowIndex * 7, (rowIndex + 1) * 7).every(k => checkboxes[k])}
                                        onChange={() => handleSelectAllForRow(rowIndex + 1)}
                                    />
                                </td>
                                {Array.from({ length: 6 }).map((_, colIndex) => {
                                    const uniqueId = `${narociloId}_cb_${rowIndex + 1}_${colIndex + 1}`;
                                    const checkboxKey = `cb_${rowIndex + 1}_${colIndex + 1}`;
                                    return (
                                        <td key={uniqueId}>
                                            <Checkbox
                                                id={uniqueId}
                                                labelText=""
                                                checked={checkboxes[checkboxKey]}
                                                onChange={() => toggleCheckbox(checkboxKey)}
                                            />
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="form-actions">
                    <Button kind="primary" onClick={handleGlobalSelectAll}>
                        Izberi / odznači vse
                    </Button>
                </div>
            </Form>
        </div>
    );
}

export default GDPRForm;
