import React, { useState, useEffect } from 'react';
import { DataTable, Pagination, TableSelectAll, TableSelectRow, Button, Modal, TextInput } from '@carbon/react';
import { Map, NextFilled, Add } from '@carbon/icons-react';
import transformCoordinates from './transformCoordiantes';
import PrikljuckiIconComponent from './PrikljuckiIconComponent';
import proj4 from 'proj4';
import './_teren-table.scss'; // Import the SCSS file
import { useAuth } from '../../AuthContext';
import createObiskiRecord from './createObiskiRecord';
import { useNavigate } from 'react-router-dom';
import { usePlanMapContext } from '../../contexts/PlanMapContext';
import generateCryptoRandomString from '../generateCryptoRandomString';


const {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
} = DataTable;


const TableComponent = ({
  tableName,
  data,
  filteredHeaders,
  currentPage,
  totalItems,
  pageSize,
  handlePaginationChange,
  selectedRows,
  setSelectedRows,
  showSelect,
  isAgent = false,

}) => {

  console.log("TableComponent received data:", data); // Log data received


  // Calculate the date 6 months ago
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

  // On modal close, set the refetchPlans state to true
  // TODO: This is a workaround to force the table to refetch data
  // TODO: You should update the data offline
  const {
    refetchPlanData, setRefetchPlanData
    //   isModalOpen, setIsModalOpen
  } = usePlanMapContext();

  // Format the date to the ISO string and split to get the date part
  const sixMonthsAgoDateString = sixMonthsAgo.toISOString().split('T')[0];

  const navigate = useNavigate();

  const navigateToSalePage = (data) => {
    navigate('/vnos', { state: { initialData: data } });
  };

  // Update the state to hold the current 'teren' data
  const [currentTerenData, setCurrentTerenData] = useState(null);

  // Where you handle the Prodano button click
  const handleProdanoClick = () => {
    console.log('handleProdanoClick currentTerenData:', currentTerenData);
    navigateToSalePage(currentTerenData);
    setIsObiskModalOpen(false);
  };

  const handleSelectAll = isSelected => {
    if (isSelected) {
      setSelectedRows(data.map(row => row.id));
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowSelect = (rowId, isSelected) => {
    if (isSelected) {
      setSelectedRows(prevState => [...prevState, rowId]);
    } else {
      setSelectedRows(prevState => prevState.filter(id => id !== rowId));
    }
  };

  const { isAuthenticated, currentUserId, pb } = useAuth();

  const extractCellData = (cells) => {
    const cellData = {};
    cells.forEach(cell => {
      cellData[cell.info.header] = cell.value;
    });
    return cellData;
  };

  // State for managing add apartment modal
  const [isAddApartmentModalOpen, setIsAddApartmentModalOpen] = useState(false);
  const [apartmentNumber, setApartmentNumber] = useState('');
  const [currentRowData, setCurrentRowData] = useState(null);

  const handleAddApartment = (rowData) => {
    setCurrentRowData(rowData);
    setIsAddApartmentModalOpen(true);
  };

  const handleModalSubmit = async () => {
    if (!apartmentNumber.trim()) {
      alert('Please enter a valid apartment number');
      return;
    }

    // Step 1: Extract parent teren data from the current row's displayed data (UI) for "prikljucki"
    const parentTerenDataFromUI = extractCellData(currentRowData.cells);

    try {
      // Step 2: Fetch the complete parent teren data from the database
      const parentTerenId = currentRowData.id;
      const parentTerenData = await pb.collection('teren').getFirstListItem(
        `id="${parentTerenId}"`
      );

      if (!parentTerenData) {
        alert('Could not find the parent teren in the database.');
        return;
      }

      // Step 3: Construct new apartment data using the fetched parent teren data, but use UI data for "prikljucki"
      const newApartmentData = {
        eid_stavba: parentTerenData.eid_stavba || "Unknown",
        opt_vrsta_subjekta_id: parentTerenData.opt_vrsta_subjekta_id || "1",
        geometry: parentTerenData.geometry || "Unknown",
        obcina_naziv: parentTerenData.obcina_naziv || "Unknown",
        naselje_naziv: parentTerenData.naselje_naziv || "Unknown",
        ulica_naziv: parentTerenData.ulica_naziv || "Unknown",
        postni_okolis_sifra: parentTerenData.postni_okolis_sifra || 0,
        postni_okolis_naziv: parentTerenData.postni_okolis_naziv || "Unknown",
        hs_stevilka: parentTerenData.hs_stevilka || "Unknown",
        prikljucki: parentTerenDataFromUI.prikljucki?.map(p => p.id) || [], // Use the prikljucki from UI
        st_stan_ali_dela: apartmentNumber, // Set from input
        vrsta_subjekta: parentTerenData.vrsta_subjekta || "Gospodinjstvo",
        parent_teren_id: parentTerenData.id,
        is_main_address: false,
        is_manually_added: true,
        st_gospodinjstev: parentTerenData.st_gospodinjstev || 1,
      };

      // Step 4: Create the new apartment record
      const newApartment = await pb.collection('teren').create(newApartmentData);
      console.log('New apartment added successfully:', newApartment);

      // Step 5: Fetch all plan_teren records associated with the parent teren
      const parentPlanTerenList = await pb.collection('plan_teren').getFullList({
        filter: `teren="${parentTerenId}"`,
        expand: 'plan',
      });

      if (!parentPlanTerenList || parentPlanTerenList.length === 0) {
        alert('No plans found for the parent teren.');
        return;
      }

      // Step 6: Iterate through each plan and create a new plan_teren record for the new apartment
      const planTerenPromises = parentPlanTerenList.map(planTeren => {
        const currentPlanId = planTeren.plan; // Assuming 'plan' is the field name

        const newPlanTerenData = {
          plan: currentPlanId,
          eid_stavba: newApartment.eid_stavba,
          st_stan_ali_dela: newApartment.st_stan_ali_dela,
          teren: newApartment.id
        };

        return pb.collection('plan_teren').create(newPlanTerenData);
      });

      // Await all promises to ensure all plan_teren records are created
      const newPlanTerenRecords = await Promise.all(planTerenPromises);
      console.log('All plan_teren records created:', newPlanTerenRecords);

      // Optionally, you can refetch data or provide user feedback here
      setRefetchPlanData(true);

    } catch (error) {
      console.error('Error adding apartment or plan_teren:', error.response?.data || error.message);
      alert(`Failed to add apartment or plan_teren: ${error.response?.message || error.message}`);
    }

    setIsAddApartmentModalOpen(false);
    setApartmentNumber('');
  };

  // Define the function to fetch or validate the plan ID
  async function getValidPlanId() {
    const planRecord = await pb.collection('plan').getList(1, 1);
    return planRecord.items[0]?.id || null;
  }

  // Define the function to fetch or validate the plan ID
  async function getValidPlanId() {
    const planRecord = await pb.collection('plan').getList(1, 1);
    return planRecord.items[0]?.id || null;
  }

  const openInMaps = (geometry) => {
    const [lat, lon] = transformCoordinates(geometry);
    if (lat && lon) {
      const mapsUrl = `https://maps.google.com/?q=${lat},${lon}`;
      window.open(mapsUrl, '_blank');
    }
  };

  const ObiskModal = ({ isOpen, onClose, onSubmit, initialData, shouldFetchData }) => {
    const { isAuthenticated, currentUserId, pb } = useAuth();
    const [odziv, setOdziv] = useState(initialData?.odziv || '');
    const [opombe, setOpombe] = useState(initialData?.opombe || '');
    const [datum, setDatum] = useState(initialData?.datum || new Date().toISOString().split('T')[0]);
    const [inputValidationError, setInputValidationError] = useState(false);
    const [obiskId, setObiskId] = useState(null);

    const handleBigButtonClick = (response) => {
      setOdziv(response);
      if (['DG', 'NŽ', 'NN'].includes(response)) {
        if (!opombe) {
          setInputValidationError(true);
        } else {
          setInputValidationError(false);
          handleSubmit(response);
        }
      } else {
        handleSubmit(response);
      }
      // Open text input for Opombe for specific responses
      // You might want to manage the visibility of the text input based on the response
    };


    // Effect hook to fetch data when the modal opens

    useEffect(() => {
      console.log('ObiskModal initialData:', initialData);

      if (isOpen) {
        const fetchObiskiData = async () => {
          const today = new Date().toISOString().split('T')[0];
          const filterString = `teren = "${currentTerenData.id}" && datum >= "${sixMonthsAgoDateString}"`;
          try {
            const resultList = await pb.collection('obiski').getList(1, 1, { filter: filterString });
            if (resultList.items.length > 0) {
              const obiskiData = resultList.items[0];
              console.log('ObiskModal obiskiData:', obiskiData);
              setOdziv(obiskiData.odziv);
              setOpombe(obiskiData.opombe);
              setObiskId(obiskiData.id);
            } else {
              console.log('ObiskModal no obiskiData found');
              setOdziv('');
              setOpombe('');
            }
          } catch (error) {
            console.error('Error fetching obiski data:', error);
          }
        };

        fetchObiskiData();
      }
    }, [isOpen, shouldFetchData, initialData, pb]);

    const handleSubmit = (response) => {
      if (['DG', 'NŽ', 'NN'].includes(response) && !opombe.trim()) {
        setInputValidationError(true);
        return;
      }

      const data = {
        teren: currentTerenData.id,
        agent: currentUserId,
        odziv: response,
        opombe: opombe,
        datum: datum,
      };

      onSubmit(pb, data, obiskId);
      onClose();
    };

    const handleInputChange = (e) => {
      setOpombe(e.target.value);
      setInputValidationError(false);
    }

    return (
      <Modal
        open={isOpen}
        onRequestClose={onClose}
        modalHeading="Obisk"
        primaryButtonText="Prekliči"
        className='modal-size'
        onRequestSubmit={onClose}
      >
        <div className="obisk-modal-content">
          <TextInput
            id="opombe"
            labelText="Opombe"
            value={opombe}
            onChange={handleInputChange}
            placeholder="Vnesite opombe"
            invalid={inputValidationError}
          />
          <br />
          <Button
            className={`obisk-modal-button dogovor ${odziv === 'DG' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('DG')}>Dogovor</Button>
          <Button
            className={`obisk-modal-button nezeli ${odziv === 'NŽ' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('NŽ')}>Ne želi</Button>
          <Button
            className={`obisk-modal-button ninavoljo ${odziv === 'NN' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('NN')}>Ni na voljo</Button>
          <Button
            className={`obisk-modal-button zema ${odziv === 'ŽI' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('ŽI')}>Že ima</Button>
          <Button
            className={`obisk-modal-button nidoma ${odziv === 'ND' ? 'active-response' : ''}`}
            onClick={() => handleBigButtonClick('ND')}>Ni doma</Button>
          <Button
            className={`obisk-modal-button prodano ${odziv === 'Prodano' ? 'active-response' : ''}`}
            onClick={() => handleProdanoClick()}>Prodano</Button>
        </div>
      </Modal>
    );
  };

  const [isObiskModalOpen, setIsObiskModalOpen] = useState(false);
  const [currentObiskData, setCurrentObiskData] = useState(null);

  const handleObiskClick = (rowData) => {
    const originalData = data.find(item => item.id === rowData.id);
    setCurrentTerenData(originalData);
    console.log('ObiskClick originalData:', originalData);

    const today = new Date().toISOString().split('T')[0];
    const obiski = originalData.obiski;
    if (!obiski) {
      setCurrentObiskData(null);
    } else {
      setCurrentObiskData(obiski[0]);
    }
    const shouldFetchData = !!currentObiskData;
    setIsObiskModalOpen(true);
  };

  const handleObiskModalClose = () => {
    setIsObiskModalOpen(false);
    setCurrentObiskData(null);
  };

  const handleObiskModalSubmit = async (pb, obiskData, obiskiId) => {
    let updatedObiski = [...(currentTerenData.obiski || [])];

    if (currentObiskData) {
      await pb.records.update('obiski', currentObiskData.id, obiskData);
      updatedObiski = updatedObiski.map(obisk => obisk.id === obiskiId ? { ...obisk, ...obiskData } : obisk);
    } else {
      await createObiskiRecord(pb, obiskData, obiskiId);
      const newObisk = { ...obiskData, id: obiskiId };
      updatedObiski.push(newObisk);
    }
    const updatedTerenData = { ...currentTerenData, obiski: updatedObiski };
    setRefetchPlanData(true);
    setIsObiskModalOpen(false);
  };


  const AddApartmentModal = ({ isOpen, onClose }) => (
    <Modal
      open={isOpen}
      modalHeading="Dodaj Stanovanje"
      primaryButtonText="Potrdi"
      secondaryButtonText="Prekliči"
      onRequestClose={onClose}
      onRequestSubmit={handleModalSubmit}
    >
      <TextInput
        id="apartment-number"
        labelText="Številka stanovanja"
        value={apartmentNumber}
        onChange={(e) => setApartmentNumber(e.target.value)}
        placeholder="Vnesite številko stanovanja"
      />
    </Modal>
  );



  return (
    <>
      <Pagination
        page={currentPage}
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={[10, 20, 30, 40, 50]}
        pageRangeText={(_current, total) => `od ${total} ${total === 1 ? 'stran' : 'strani'}`}
        itemRangeText={(min, max, total) => `${min}–${max} od ${total} zapisov`}
        itemsPerPageText="Zapisov na stran"
        backwardText="Prejšnja stran"
        forwardText="Naslednja stran"
        onChange={(event) => handlePaginationChange(event)}
      />

      <DataTable
        rows={data}
        headers={filteredHeaders}
      >
        {({
          rows,
          headers,
          getHeaderProps,
          getRowProps,
          getSelectionProps,
          getTableProps,
          getTableContainerProps
        }) => (
          <TableContainer title={tableName} {...getTableContainerProps()}>
            <Table {...getTableProps()} aria-label="Teren table">
              <TableHead>
                <TableRow>
                  {showSelect && (
                    <TableSelectAll
                      {...getSelectionProps({
                        onClick: (e) => handleSelectAll(e.target.checked)
                      })}
                      checked={selectedRows.length === data.length}
                      indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
                    />
                  )}
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header })} key={header.key}>
                      {header.header}
                    </TableHeader>
                  ))}
                  {isAgent && (
                    <TableHeader key="obisk">Obisk</TableHeader>
                  )}
                  <TableHeader key="stanovanje">Stanovanje</TableHeader>
                  {/* <TableHeader key="is-manually-added">Manually Added</TableHeader> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  // Find the original data for this row
                  const rowData = data.find(item => item.id === row.id);

                  return (
                    <TableRow key={row.id} {...getRowProps({ row })}>
                      {showSelect && (
                        <TableSelectRow
                          {...getSelectionProps({
                            row,
                            onClick: () => handleRowSelect(row.id, !selectedRows.includes(row.id))
                          })}
                          checked={selectedRows.includes(row.id)}
                        />
                      )}
                      {row.cells.map((cell) => {
                        if (cell.info.header === 'prikljucki') {
                          return (
                            <TableCell key={cell.id}>
                              <div className="prikljucki-container">
                                {cell.value ? cell.value.map(prikljuckiRecord => (
                                  <PrikljuckiIconComponent key={prikljuckiRecord.id} prikljucki={prikljuckiRecord} />
                                )) : null}
                              </div>
                            </TableCell>
                          );
                        } else if (cell.info.header === 'geometry') {
                          if (!cell.value) {
                            return <TableCell key={cell.id}></TableCell>;
                          } else {
                            return (
                              <TableCell key={cell.id}>
                                <Button
                                  renderIcon={() => <Map size={20} />}
                                  iconDescription="Usmeri"
                                  hasIconOnly
                                  onClick={() => openInMaps(cell.value)}
                                  size="sm"
                                  kind="ghost"
                                />
                              </TableCell>
                            );
                          }
                        } else {
                          return <TableCell key={cell.id}>{cell.value}</TableCell>;
                        }
                      })}
                      {isAgent && (
                        <TableCell key={`${row.id}-obisk`}>
                          <Button
                            renderIcon={() => <NextFilled size={20} />}
                            iconDescription="Obisk"
                            hasIconOnly
                            onClick={() => handleObiskClick(row)}
                            size="md"
                            kind="primary"
                          />
                        </TableCell>
                      )}
                      <TableCell key={`${row.id}-add-apartment`}>
                        {!rowData?.teren?.is_manually_added && (
                          <Button
                            renderIcon={() => <Add size={20} />}
                            iconDescription="Dodaj stanovanje"
                            hasIconOnly
                            onClick={() => handleAddApartment(row)}
                            size="md"
                            kind="primary"
                          />
                        )}
                      </TableCell>
                      {/*
                      <TableCell key={`${row.id}-is-manually-added`}>
                        {rowData?.teren?.is_manually_added ? 'Yes' : 'No'}
                      </TableCell>
                      */}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>

      <ObiskModal
        isOpen={isObiskModalOpen}
        onClose={handleObiskModalClose}
        onSubmit={handleObiskModalSubmit}
        initialData={currentObiskData}
        shouldFetchData={!!currentObiskData}
      />

      <AddApartmentModal
        isOpen={isAddApartmentModalOpen}
        onClose={() => setIsAddApartmentModalOpen(false)}
      />
    </>
  );
};

export default TableComponent;
