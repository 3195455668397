import React, { useState, useEffect } from 'react';
import {
    DataTable,
    DataTableSkeleton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Button,
    TextInput,
    Modal,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Pagination,
    FilterableMultiSelect,
    Dropdown,
    Checkbox
} from '@carbon/react';
import './CSS/Admin.css';
import { useAuth } from '../../AuthContext';

const UsersAdmin = () => {
    const { pb } = useAuth();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentRecordId, setCurrentRecordId] = useState(null);
    const [formValues, setFormValues] = useState({
        username: '',
        email: '',
        password: '',
        passwordConfirm: '',
        name: '',
        phonenum: '',
        role: 'agent',  // default role
    });
    const [showPassword, setShowPassword] = useState(false);
    const [passwordError, setPasswordError] = useState('');

    const fetchData = async () => {
        setLoading(true);
        try {
            const resultList = await pb.collection('users').getList(page, perPage, { sort: '-created' });
            setData(resultList.items);
            setTotalItems(resultList.totalItems);
        } catch (error) {
            console.error('Napaka pri pridobivanju uporabnikov:', error);
        } finally {
            setLoading(false);
        }
    };

    const updateEmailVisibility = async () => {
        try {
            const records = await pb.collection('users').getFullList({
                filter: 'emailVisibility=false',
            });
    
            for (let record of records) {
                await pb.collection('users').update(record.id, { emailVisibility: true });
            }
    
            console.log('Email visibility updated for all users.');
        } catch (error) {
            console.error('Error updating email visibility:', error);
        }
    };
    
    updateEmailVisibility();

    useEffect(() => {
        fetchData();
    }, [page, perPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleInputChange = (field, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
        if (field === 'password' || field === 'passwordConfirm') {
            validatePassword(value);
        }
    };

    const validatePassword = (password) => {
        if (password.length < 8 || password.length > 72) {
            setPasswordError('Geslo mora imeti med 8 in 72 znakov.');
        } else {
            setPasswordError('');
        }
    };

    const handleFormSubmit = async () => {
        if (passwordError) return; 

        try {
            if (isEditMode) {
                await pb.collection('users').update(currentRecordId, formValues);
            } else {
                await pb.collection('users').create(formValues);
            }
            setIsModalOpen(false);
            setIsEditMode(false);
            fetchData();
        } catch (error) {
            console.error('Napaka pri ustvarjanju/posodabljanju uporabnika:', error);
        }
    };

    const handleAddNewRecord = () => {
        setFormValues({
            username: '',
            email: '',
            password: '',
            passwordConfirm: '',
            name: '',
            phonenum: '',
            role: 'agent',
        });
        setIsEditMode(false);
        setIsModalOpen(true);
    };

    const handleEditRecord = (record) => {
        setFormValues({
            username: record.username,
            email: record.email,
            name: record.name,
            phonenum: record.phonenum,
            role: record.role,
        });
        setCurrentRecordId(record.id);
        setIsEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteRecord = async (recordId) => {
        if (window.confirm('Ali ste prepričani, da želite izbrisati tega uporabnika?')) {
            try {
                await pb.collection('users').delete(recordId);
                fetchData();
            } catch (error) {
                console.error('Napaka pri brisanju uporabnika:', error);
            }
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <DataTable rows={data.map((record) => ({
            id: record.id,
            username: record.username,
            email: record.email,
            name: record.name,
            role: record.role,
            actions: (
                <div>
                    <Button onClick={() => handleEditRecord(record)} size="small" kind="ghost">Uredi</Button>
                    <Button onClick={() => handleDeleteRecord(record.id)} size="small" kind="ghost" className="delete-button">Izbriši</Button>
                </div>
            ),
        }))} headers={[
            { key: 'username', header: 'Uporabniško ime' },
            { key: 'email', header: 'Email' },
            { key: 'name', header: 'Ime' },
            { key: 'role', header: 'Vloga' },
            { key: 'actions', header: 'Akcije' },
        ]}>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getToolbarProps,
                getTableContainerProps,
            }) => (
                <TableContainer title="Upravljanje uporabnikov" description="Upravljanje sistemskih uporabnikov" {...getTableContainerProps()}>
                    <TableToolbar {...getToolbarProps()}>
                        <TableToolbarContent>
                            <TableToolbarSearch onChange={() => {}} />
                            <Button onClick={handleAddNewRecord}>Dodaj uporabnika</Button>
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })} key={header.key}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow {...getRowProps({ row })} key={row.id}>
                                    {row.cells.map((cell) => (
                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        page={page}
                        pageSize={perPage}
                        pageSizes={[10, 20, 30, 50]}
                        totalItems={totalItems}
                        onChange={({ page, pageSize }) => {
                            handlePageChange(page);
                            handlePerPageChange(pageSize);
                        }}
                        itemRangeText={(min, max, total) => `${min}–${max} od ${total} uporabnikov`}
                        pageRangeText={(current, total) => `Stran ${current} od ${total}`}
                        backwardText="Prejšnja stran"
                        forwardText="Naslednja stran"
                        itemsPerPageText="Uporabnikov na stran"
                    />

                    <Modal
                        open={isModalOpen}
                        modalHeading={isEditMode ? "Uredi uporabnika" : "Dodaj novega uporabnika"}
                        primaryButtonText={isEditMode ? "Posodobi uporabnika" : "Dodaj uporabnika"}
                        secondaryButtonText="Prekliči"
                        onRequestClose={() => setIsModalOpen(false)}
                        onRequestSubmit={handleFormSubmit}
                    >
                        <TextInput
                            id="username"
                            labelText="Uporabniško ime"
                            value={formValues.username}
                            onChange={(e) => handleInputChange('username', e.target.value)}
                        />
                        <TextInput
                            id="email"
                            labelText="Email"
                            value={formValues.email}
                            onChange={(e) => handleInputChange('email', e.target.value)}
                        />
                        <TextInput
                            id="password"
                            labelText="Geslo"
                            value={formValues.password}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => handleInputChange('password', e.target.value)}
                            invalid={!!passwordError}
                            invalidText={passwordError}
                        />
                        <TextInput
                            id="passwordConfirm"
                            labelText="Potrdi geslo"
                            value={formValues.passwordConfirm}
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => handleInputChange('passwordConfirm', e.target.value)}
                        />
                        <Checkbox
                            labelText="Prikaži geslo"
                            id="show-password"
                            checked={showPassword}
                            onChange={togglePasswordVisibility}
                        />
                        <TextInput
                            id="name"
                            labelText="Ime in Priimek"
                            value={formValues.name}
                            onChange={(e) => handleInputChange('name', e.target.value)}
                        />
                        <TextInput
                            id="phonenum"
                            labelText="Telefonska številka"
                            value={formValues.phonenum}
                            onChange={(e) => handleInputChange('phonenum', e.target.value)}
                        />
                        <Dropdown
                            id="role"
                            titleText="Vloga"
                            label="Izberite vlogo"
                            items={['agent', 'admin']}
                            selectedItem={formValues.role}
                            onChange={({ selectedItem }) => handleInputChange('role', selectedItem)}
                        />
                    </Modal>
                </TableContainer>
            )}
        </DataTable>
    );
};

export default UsersAdmin;