import React, { useState, useEffect } from 'react';
import {
  DataTable,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
} from '@carbon/react';
import PocketBase from 'pocketbase';
import { useAuth } from '../../AuthContext';

const Interesanti = () => {
  const { pb } = useAuth();
  const [leads, setLeads] = useState([]);
  const [userRole, setUserRole] = useState('agent'); // Default to agent

  // Fetch current user's role (admin or agent)
  const fetchUserRole = async () => {
    try {
      const currentUserId = localStorage.getItem('currentUserId');
      const user = await pb.collection('users').getFirstListItem(`id="${currentUserId}"`);

      // Set the role (either 'admin' or 'agent')
      setUserRole(user.role);
    } catch (error) {
      console.error('Error fetching user role:', error);
    }
  };

  const fetchLeads = async () => {
    try {
      const currentUserId = localStorage.getItem('currentUserId');

      // If the user is an admin, fetch all leads and expand the agent relation
      const filterQuery = userRole === 'admin' ? 'is_lead = true' : `is_lead = true && agent = "${currentUserId}"`;

      const result = await pb.collection('stranke').getFullList({
        filter: filterQuery,
        sort: '-created',
        expand: 'agent', // Expand the agent field to access agent details
      });

      const relevantLeads = result.map((lead) => ({
        id: lead.id,
        ime: lead.ime,
        priimek: lead.priimek,
        email: lead.email,
        kontakt_tel: lead.kontakt_tel,
        naslov: `${lead.ulica_naziv || ''} ${lead.hs_stevilka || ''}, ${lead.postni_okolis_naziv || ''} ${lead.postni_okolis_sifra || ''}`,
        lead_opomba: lead.lead_opomba || '',
        lead_datum: new Date(lead.lead_datum).toLocaleDateString(),
        agent: lead.expand?.agent?.name || 'N/A',
      }));

      setLeads(relevantLeads);
    } catch (error) {
      console.error('Error fetching leads:', error);
    }
  };

  useEffect(() => {
    fetchUserRole();
  }, []);

  useEffect(() => {
    if (userRole) {
      fetchLeads();
    }
  }, [userRole]);

  const headers = [
    { key: 'ime', header: 'Ime' },
    { key: 'priimek', header: 'Priimek' },
    { key: 'email', header: 'Email' },
    { key: 'kontakt_tel', header: 'Telefon' },
    { key: 'naslov', header: 'Naslov' },
    { key: 'lead_datum', header: 'Datum interesa' },
    { key: 'lead_opomba', header: 'Opombe' },
    { key: 'agent', header: 'Agent' }, // Add a column for agent
  ];

  return (
    <DataTable rows={leads} headers={headers}>
      {({
        rows,
        headers,
        getHeaderProps,
        getRowProps,
        getTableProps,
        getToolbarProps,
        getTableContainerProps,
        onInputChange,
      }) => (
        <TableContainer title="Seznam interesentov" {...getTableContainerProps()}>
          <TableToolbar {...getToolbarProps()}>
            <TableToolbarContent>
              <TableToolbarSearch onChange={onInputChange} />
            </TableToolbarContent>
          </TableToolbar>
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </DataTable>
  );
};

export default Interesanti;