import React, { useState, useEffect } from 'react';
import {
    DataTable,
    DataTableSkeleton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Button,
    TextInput,
    DatePicker,
    DatePickerInput,
    Modal,
    TableToolbar,
    TableToolbarContent,
    TableToolbarSearch,
    Pagination,
    FilterableMultiSelect,
} from '@carbon/react';
import './CSS/Admin.css';
import { useAuth } from '../../AuthContext';

const AkcijeAdmin = () => {
    const { pb, currentUserId, isAuthenticated } = useAuth();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [paketiOptions, setPaketiOptions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentRecordId, setCurrentRecordId] = useState(null);
    const [formValues, setFormValues] = useState({
        popust: '',
        velja_od: '',
        velja_do: '',
        paketi: [],
    });
    const [filteredData, setFilteredData] = useState([]); // State to hold filtered data

    // Fetch paketi options for dropdown
    useEffect(() => {
        const fetchPaketiOptions = async () => {
            try {
                const resultList = await pb.collection('paketi').getFullList({ sort: '-created' });
                setPaketiOptions(resultList.map((paket) => ({ id: paket.id, name: paket.paket })));
            } catch (error) {
                console.error('Ni uspelo pridobiti možnosti paketov:', error);
            }
        };

        fetchPaketiOptions();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const resultList = await pb.collection('paketi_akcije').getList(page, perPage, {
                expand: 'paket',
                filter: 'created >= "2022-01-01 00:00:00"',
                sort: '-created',
            });
            setData(resultList.items);
            setFilteredData(resultList.items); // Initialize filteredData
            setTotalItems(resultList.totalItems);
        } catch (error) {
            console.error('Ni uspelo pridobiti podatkov:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, perPage]);

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleSearchChange = async (event) => {
        const searchTerm = event.target.value.toLowerCase();
        setLoading(true); 
        
        try {
            const filterQuery = `
            popust ~ "${searchTerm}" ||
            paket.paket ~ "${searchTerm}"
        `;    
            const resultList = await pb.collection('paketi_akcije').getList(1, perPage, {
                expand: 'paket',
                filter: filterQuery,
                sort: '-created',
            });
    
            console.log('Search result:', resultList); 
    
            if (resultList && resultList.items) {
                setFilteredData(resultList.items); 
                setTotalItems(resultList.totalItems);
            } else {
                setFilteredData([]); 
            }
        } catch (error) {
            console.error('Error fetching search results:', error);
            setFilteredData([]); 
        } finally {
            setLoading(false);  
        }
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const handlePerPageChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handleInputChange = (field, value) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [field]: value,
        }));
    };

    const handleFormSubmit = async () => {
        try {
            if (isEditMode) {
                await pb.collection('paketi_akcije').update(currentRecordId, formValues);
            } else {
                const promises = formValues.paketi.map((paket) => {
                    const newCampaign = { ...formValues, paket };
                    return pb.collection('paketi_akcije').create(newCampaign);
                });
                await Promise.all(promises);
            }
            setIsModalOpen(false);
            setIsEditMode(false);

            fetchData();
        } catch (error) {
            console.error('Ni uspelo ustvariti/posodobiti zapisa:', error);
        }
    };

    const handleAddNewRecord = () => {
        setFormValues({
            popust: '',
            velja_od: '',
            velja_do: '',
            paketi: [],
        });
        setIsEditMode(false);
        setIsModalOpen(true);
    };

    const handleEditRecord = (record) => {
        setFormValues({
            popust: record.popust,
            velja_od: formatDate(record.velja_od),
            velja_do: formatDate(record.velja_do),
            paketi: [record.paket],
        });
        setCurrentRecordId(record.id);
        setIsEditMode(true);
        setIsModalOpen(true);
    };

    const handleDeleteRecord = async (recordId) => {
        if (window.confirm('Ali ste prepričani, da želite izbrisati ta zapis?')) {
            try {
                await pb.collection('paketi_akcije').delete(recordId);
                fetchData();
            } catch (error) {
                console.error('Ni uspelo izbrisati zapisa:', error);
            }
        }
    };

    return (
        <DataTable rows={filteredData.map((record) => ({
            id: record.id,
            popust: record.popust,
            velja_od: formatDate(record.velja_od),
            velja_do: formatDate(record.velja_do),
            paket: record.expand?.paket?.paket || 'Neznano',
            actions: (
                <div>
                    <Button
                        onClick={() => handleEditRecord(record)}
                        size="small"
                        kind="ghost"
                    >
                        Uredi
                    </Button>
                    <Button
                        onClick={() => handleDeleteRecord(record.id)}
                        size="small"
                        kind="ghost"
                        className="delete-button"
                    >
                        Izbriši
                    </Button>
                </div>
            ),
        }))} headers={[
            { key: 'popust', header: 'Popust' },
            { key: 'velja_od', header: 'Velja Od' },
            { key: 'velja_do', header: 'Velja Do' },
            { key: 'paket', header: 'Paket' },
            { key: 'actions', header: 'Akcije' },
        ]}>
            {({
                rows,
                headers,
                getHeaderProps,
                getRowProps,
                getTableProps,
                getToolbarProps,
                getTableContainerProps,
            }) => (
                <TableContainer title="Seznam akcij" description="Seznam akcij na paketih" {...getTableContainerProps()}>
                    <TableToolbar {...getToolbarProps()}>
                        <TableToolbarContent>
                            <TableToolbarSearch onChange={handleSearchChange} />
                            <Button onClick={handleAddNewRecord}>Dodaj Akcijo</Button>
                        </TableToolbarContent>
                    </TableToolbar>
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })} key={header.key}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow {...getRowProps({ row })} key={row.id}>
                                    {row.cells.map((cell) => (
                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Pagination
                        page={page}
                        pageSize={perPage}
                        pageSizes={[10, 20, 30, 50]}
                        totalItems={totalItems}
                        pageRangeText={(_current, total) => `od ${total} ${total === 1 ? 'stran' : 'strani'}`}
                        itemRangeText={(min, max, total) => `${min}–${max} od ${total} zapisov`}
                        itemsPerPageText="Zapisov na stran"
                        backwardText="Prejšnja stran"
                        forwardText="Naslednja stran"
                        onChange={({ page, pageSize }) => {
                            handlePageChange(page);
                            handlePerPageChange(pageSize);
                        }}
                    />

                    {/* Modal for Adding/Editing a Record */}
                    <Modal
                        open={isModalOpen}
                        modalHeading={isEditMode ? "Uredi zapis Paketi Akcije" : "Dodaj nov zapis Paketi Akcije"}
                        primaryButtonText={isEditMode ? "Posodobi zapis" : "Dodaj zapis"}
                        secondaryButtonText="Prekliči"
                        onRequestClose={() => setIsModalOpen(false)}
                        onRequestSubmit={handleFormSubmit}
                    >
                        <TextInput
                            id="popust"
                            labelText="Popust"
                            value={formValues.popust}
                            onChange={(e) => handleInputChange('popust', e.target.value)}
                        />
                        <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="single"
                            onChange={(dates) => handleInputChange('velja_od', dates[0].toLocaleDateString('en-CA'))}
                        >
                            <DatePickerInput
                                id="velja_od"
                                placeholder="yyyy-mm-dd"
                                labelText="Velja Od"
                                value={formValues.velja_od}
                            />
                        </DatePicker>
                        <DatePicker
                            dateFormat="Y-m-d"
                            datePickerType="single"
                            onChange={(dates) => handleInputChange('velja_do', dates[0].toLocaleDateString('en-CA'))}
                        >
                            <DatePickerInput
                                id="velja_do"
                                placeholder="yyyy-mm-dd"
                                labelText="Velja Do"
                                value={formValues.velja_do}
                            />
                        </DatePicker>
                        <br />
                        <FilterableMultiSelect
                            id="paketi"
                            label="Izberite Pakete"
                            items={paketiOptions}
                            itemToString={(item) => (item ? item.name : '')}
                            onChange={({ selectedItems }) => handleInputChange('paketi', selectedItems.map(item => item.id))}
                            initialSelectedItems={paketiOptions.filter(item => formValues.paketi.includes(item.id))}
                        />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />

                    </Modal>
                </TableContainer>
            )}
        </DataTable>
    );
};

export default AkcijeAdmin;