import React from 'react';
import { Switcher, Notification, UserAvatar, Logout } from '@carbon/react/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import {
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  HeaderSideNavItems,
} from '@carbon/react'; 

import './_agentron-header.scss';

const AgentronHeader = () => {
  const { logout, isTestDatabase, currentUserRole } = useAuth(); 
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login'); 
    localStorage.removeItem('selectedPlanId');
    sessionStorage.clear();
    
    };

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label="Agentron">
          <SkipToContent />
          <HeaderMenuButton
            aria-label="Open menu"
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName as={Link} to="/" prefix="Agentron">
            Prodaja na terenu
          </HeaderName>
          <HeaderNavigation aria-label="Plan">
            <HeaderMenuItem as={Link} to="/plan">
              Plani
            </HeaderMenuItem>
            <HeaderMenuItem as={Link} to="/vnos">
              Vnos
            </HeaderMenuItem>
            <HeaderMenuItem as={Link} to="/analitika">
              Analitika
            </HeaderMenuItem>
            <HeaderMenuItem as={Link} to="/interesanti">
              Interesanti
            </HeaderMenuItem>
            {(currentUserRole === 'admin' || currentUserRole === 'author') && (
              <>
                <HeaderMenuItem as={Link} to="/admin-dash">
                  Admin
                </HeaderMenuItem>
              </>
            )}
          </HeaderNavigation>
          <SideNav
            aria-label="Side navigation"
            expanded={isSideNavExpanded}
            isPersistent={false}
          >
            <SideNavItems>
              <HeaderSideNavItems>
                <HeaderMenuItem as={Link} to="/plan">
                  Plani
                </HeaderMenuItem>
                <HeaderMenuItem as={Link} to="/vnos">
                  Vnos
                </HeaderMenuItem>
                <HeaderMenuItem as={Link} to="/analitika">
                  Analitika
                </HeaderMenuItem>
                <HeaderMenuItem as={Link} to="/interesanti">
                  Interesanti
                </HeaderMenuItem>
                {(currentUserRole === 'admin' || currentUserRole === 'author') && (
                  <>
                    <HeaderMenuItem as={Link} to="/admin-dash">
                      Admin
                    </HeaderMenuItem>
                  </>
                )}
              </HeaderSideNavItems>
            </SideNavItems>
          </SideNav>
          <HeaderGlobalBar>
            {isTestDatabase && (
              <div className="test-database-indicator">Testna baza!</div>
            )}
            <HeaderGlobalAction aria-label="Notifications" tooltipAlignment="center">
              <Notification size={20} />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="Profile"
              tooltipAlignment="center"
              as={Link}
              to="/profile" 
            >
              <UserAvatar size={20} />
            </HeaderGlobalAction>
            <HeaderGlobalAction
              aria-label="Odjava"
              tooltipAlignment="center"
              onClick={handleLogout}
            >
              <Logout size={20} /> 
            </HeaderGlobalAction>
            <HeaderGlobalAction aria-label="App Switcher" tooltipAlignment="end">
              <Switcher size={20} />
            </HeaderGlobalAction>
          </HeaderGlobalBar>
        </Header>
      )}
    />
  );
};

export default AgentronHeader;