import React from 'react';
import { Button, Content, Theme, InlineNotification } from '@carbon/react';
import { Route, Routes, Navigate } from 'react-router-dom';
import useNetworkStatus from './hooks/useNetworkStatus';
import AgentronHeader from './components/AgentronHeader';
import LandingPage from './content/LandingPage';
import PlanMapPage from './content/PlanMapPage';
import SalePage from './content/SalePage';
import AuthenticationPage from './content/AuthenticationPage';
import { useAuth } from './AuthContext';
import DocumentSignPage from './content/DocumentSignPage';
import AnalyticsPage from './content/AnalyticsPage';
import XLSUploadPage from './content/XLSUploadPage';
import { PlanMapProvider } from './contexts/PlanMapContext';
import './themes/agentron-theme.scss';
import Interesanti from './content/Leads/Interesanti';
import LeadButton from './content/Leads/LeadButton';
import NarocilaPage from './content/AdminPage/OrdersAdmin';
import NotAuthorizedPage from './content/NotAuthorized/NotAuthorizedPage';
import AdminPage from './content/AdminPage/AdminPage';
import ProfilePage from './content/UserPages/ProfilePage';

function ProtectedRoute({ children, allowedRoles }) {
  const { isAuthenticated, currentUserRole } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(currentUserRole)) {
    return <Navigate to="/not-authorized" />;
  }

  return children;
}

function App() {
  const { isAuthenticated } = useAuth();
  const isOnline = useNetworkStatus();

  return (
    <PlanMapProvider>
      <>
        {isAuthenticated && (
          <Theme theme="g100">
            <AgentronHeader />
          </Theme>
        )}
        <Content>
          {!isOnline && (
            <div style={{ marginBottom: '1rem' }}>
              <InlineNotification
                kind="error"
                title="Ni internetne povezave"
                subtitle="Trenutno ste brez povezave. Preverite svojo internetno povezavo."
                hideCloseButton={true}
              />
            </div>
          )}
          <LeadButton />
          <Routes>
            <Route path="/login" element={<AuthenticationPage />} />
            <Route path="/*" element={
              <ProtectedRoute>
                <Routes>
                  <Route index element={<PlanMapPage />} />
                  <Route path="/admin" element={<LandingPage />} />
                  <Route path="/plan" element={<PlanMapPage />} />
                  <Route path="/vnos" element={<SalePage />} />
                  <Route path="/podpis" element={<DocumentSignPage />} />
                  <Route path="/analitika" element={<AnalyticsPage />} />
                  <Route path="/xls" element={<XLSUploadPage />} />
                  <Route path="/interesanti" element={<Interesanti />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/narocila" element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <NarocilaPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/admin-dash/*" element={
                    <ProtectedRoute allowedRoles={['admin']}>
                      <AdminPage />
                    </ProtectedRoute>
                  } />
                  <Route path="/not-authorized" element={<NotAuthorizedPage />} />
                </Routes>
              </ProtectedRoute>
            } />
          </Routes>
        </Content>
      </>
    </PlanMapProvider>
  );
}

export default App;