import React, { useState, useEffect, useRef } from 'react';
import {
    Button,
    Modal,
    Form,
    TextInput,
    TextArea,
    Checkbox,
    ToastNotification,
} from '@carbon/react';
import PocketBase from 'pocketbase';
import config from '../../config.json';
import axios from 'axios';
import { useAuth } from '../../AuthContext';
import SignaturePad from 'signature_pad';

const OPEN_CAGE_API_KEY = config.openCageApiKey;

const LeadButton = () => {
    const { pb } = useAuth();

    const [isOpen, setIsOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [leadData, setLeadData] = useState({
        ime: '',
        priimek: '',
        email: '',
        kontakt_tel: '',
        street: '',
        house_number: '',
        city: '',
        postalCode: '',
        opombe: '',
        lead_datum: new Date(),
        is_lead: true,
    });
    const [errors, setErrors] = useState({});
    const [isAgreed, setIsAgreed] = useState(false);
    const canvasRef = useRef(null);
    const [signaturePad, setSignaturePad] = useState(null);
    const [notification, setNotification] = useState(null);

    const togglePopup = () => {
        setIsOpen(!isOpen);
        setCurrentStep(1);
    };

    const resetForm = () => {
        setLeadData({
            ime: '',
            priimek: '',
            email: '',
            kontakt_tel: '',
            street: '',
            house_number: '',
            city: '',
            postalCode: '',
            opombe: '',
            lead_datum: new Date(),
            is_lead: true,
        });
        setErrors({});
        setIsAgreed(false);
        if (signaturePad) {
            signaturePad.clear();
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^\d+$/; // Simple regex for digits only, adjust based on format required
        return phoneRegex.test(phone);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let newErrors = { ...errors };
        if (name === 'email' && !validateEmail(value)) {
            newErrors.email = 'Nepravilen email format';
        } else {
            delete newErrors.email;
        }

        if (name === 'kontakt_tel' && !validatePhoneNumber(value)) {
            newErrors.kontakt_tel = 'Vnesite samo številke';
        } else {
            delete newErrors.kontakt_tel;
        }

        setErrors(newErrors);
        setLeadData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (e) => {
        setIsAgreed(e.target.checked);
    };

    const handleNextStep = () => {
        if (currentStep === 1) {
            setCurrentStep(2);
        } else if (!isAgreed) {
            alert('Prosimo, strinjajte se s pogoji in določili za nadaljevanje.');
            return;
        }
    };

    const handlePrevStep = () => {
        setCurrentStep(1);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        if (!isAgreed) {
            alert('Prosimo, strinjajte se s pogoji in določili za nadaljevanje.');
            return;
        }
    
        if (signaturePad && signaturePad.isEmpty()) {
            alert('Prosimo, podajte svoj podpis za nadaljevanje.');
            return;
        }
    
        if (Object.keys(errors).length > 0) {
            alert('Please correct the errors before submitting.');
            return;
        }
    
        const signatureImageURL = signaturePad.toDataURL('image/jpeg');
        const blob = await fetch(signatureImageURL).then((res) => res.blob());
        const signatureFile = new File([blob], 'signature.jpg', { type: 'image/jpeg' });
    
        // Retrieve the currentUserId from localStorage
        const currentUserId = localStorage.getItem('currentUserId');
    
        // Create formData and add the agent ID
        const formData = new FormData();
        formData.append('ime', leadData.ime);
        formData.append('priimek', leadData.priimek);
        formData.append('ulica_naziv', leadData.street);
        formData.append('hs_stevilka', leadData.house_number);
        formData.append('postni_okolis_naziv', leadData.city);
        formData.append('postni_okolis_sifra', leadData.postalCode);
        formData.append('email', leadData.email);
        formData.append('kontakt_tel', leadData.kontakt_tel);
        formData.append('lead_opomba', leadData.opombe);
        formData.append('lead_datum', leadData.lead_datum.toISOString());
        formData.append('is_lead', leadData.is_lead);
        formData.append('lead_strinjanje', isAgreed);
        formData.append('lead_podpis', signatureFile);
        formData.append('agent', currentUserId); // Add the agent ID
    
        try {
            await pb.collection('stranke').create(formData);
            togglePopup();
            resetForm();
            setNotification({
                kind: 'success',
                title: 'Uspeh',
                subtitle: 'Interesant je bil uspešno poslan.',
            });
        } catch (error) {
            console.error('Error creating lead:', error);
            setNotification({
                kind: 'error',
                title: 'Napaka',
                subtitle: 'Prišlo je do napake pri pošiljanju interesanta. Poskusite znova.',
            });
        }
    };

    useEffect(() => {
        if (currentStep === 2 && canvasRef.current) {
            const pad = new SignaturePad(canvasRef.current, {
                backgroundColor: 'white',
                penColor: 'black',
            });
            setSignaturePad(pad);
        }
    }, [currentStep]);

    const fetchLocationData = async (lat, lng) => {
        try {
            const response = await axios.get(
                `https://api.opencagedata.com/geocode/v1/json?q=${lat}+${lng}&key=${OPEN_CAGE_API_KEY}&language=sl&pretty=1`
            );
            const data = response.data.results[0].components;
            setLeadData((prevState) => ({
                ...prevState,
                street: data.road || '',
                house_number: data.house_number || '',
                city: data.city || data.town || data.village || '',
                postalCode: data.postcode || '',
            }));
        } catch (error) {
            console.error('Error fetching location data:', error);
        }
    };

    const getCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    fetchLocationData(latitude, longitude);
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            alert('Geolokacija ni podprta v tem brskalniku.');
        }
    };

    useEffect(() => {
        if (isOpen) {
            getCurrentLocation();
        }
    }, [isOpen]);

    return (
        <>
            <div
                style={{
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 1000,
                }}
            >
                <Button
                    onClick={() => {
                        resetForm();
                        setIsOpen(true);
                    }}
                    style={{
                        borderRadius: '50px',
                        padding: '15px 15px 15px 15px',
                        border: 'none',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    +
                </Button>
            </div>

            <Modal
                open={isOpen}
                modalHeading="Dodaj Interesanta"
                primaryButtonText={currentStep === 2 ? 'Potrdi' : 'Naprej'}
                secondaryButtonText={currentStep === 1 ? 'Prekliči' : 'Nazaj'}
                onRequestClose={togglePopup}
                onRequestSubmit={currentStep === 2 ? handleSubmit : handleNextStep}
                onSecondarySubmit={currentStep === 1 ? togglePopup : handlePrevStep}
            >
                {currentStep === 1 && (
                    <Form>
                        <div className="form-row">
                            <TextInput
                                labelText="Ime"
                                id="name"
                                name="ime"
                                value={leadData.ime}
                                onChange={handleInputChange}
                                placeholder="Janez"
                            />
                            <TextInput
                                labelText="Priimek"
                                id="surname"
                                name="priimek"
                                value={leadData.priimek}
                                onChange={handleInputChange}
                                placeholder="Novak"
                            />
                        </div>
                        <div className="form-row">
                            <TextInput
                                labelText="Email"
                                id="email"
                                name="email"
                                value={leadData.email}
                                onChange={handleInputChange}
                                placeholder="janez.novak@gmail.com"
                                invalid={!!errors.email}
                                invalidText={errors.email}
                            />
                            <TextInput
                                labelText="Telefon"
                                id="phone"
                                name="kontakt_tel"
                                value={leadData.kontakt_tel}
                                onChange={handleInputChange}
                                placeholder="070 700 700"
                                invalid={!!errors.kontakt_tel}
                                invalidText={errors.kontakt_tel}
                            />
                        </div>
                        <div className="form-row">
                            <TextInput
                                labelText="Ulica"
                                id="street"
                                name="street"
                                value={leadData.street}
                                onChange={handleInputChange}
                                placeholder="Slovenska cesta"
                                className="form-two-thirds-width"
                            />
                            <TextInput
                                labelText="Hišna številka"
                                id="house_number"
                                name="house_number"
                                value={leadData.house_number}
                                onChange={handleInputChange}
                                placeholder="1a"
                                className="form-third-width"
                            />
                        </div>
                        <div className="form-row">
                            <TextInput
                                labelText="Poštna koda"
                                id="postalCode"
                                name="postalCode"
                                value={leadData.postalCode}
                                onChange={handleInputChange}
                                placeholder="1000"
                                className="form-third-width"
                            />
                            <TextInput
                                labelText="Mesto"
                                id="city"
                                name="city"
                                value={leadData.city}
                                onChange={handleInputChange}
                                placeholder="Ljubljana"
                                className="form-two-thirds-width"
                            />
                        </div>
                        <div className="form-row">
                            <TextArea
                                labelText="Opombe"
                                id="notes"
                                name="opombe"
                                value={leadData.opombe}
                                onChange={handleInputChange}
                                placeholder="stac. tel. GRATIS; stranka je dosegljiva le dopoldan"
                                rows={1}
                                style={{ resize: 'none', width: '100%' }}
                            />
                        </div>
                    </Form>
                )}

                {currentStep === 2 && (
                    <Form>
                        <div className="form-row">
                            <Checkbox
                                id="agree"
                                labelText="Želim, da me na posredovane kontaktne podatke obveščate o ponudbi elektronskih komunikacijskih storitev (tudi za osebno prilagojeno ponudbo s pomočjo oblikovanja profilov) družbe Telemach Slovenija. Osebni podatki se obdelujejo do vašega preklica, ki ga lahko podate prek elektronske pošte na info@telemach.si. Več o vaših pravicah v zvezi z varstvom osebnih podatkov najdete v pogojih nagradne igre in v Pogojih varovanja osebnih podatkov, ki so dostopni tukaj. Za vprašanja v zvezi z obdelavo osebnih podatkov se lahko obrnete na pooblaščenca za varstvo osebnih podatkov na dpo@telemach.si ali po pošti, na Telemach Slovenija d.o.o., Pooblaščenec za varstvo osebnih podatkov, Brnčičeva ulica 49A, 1231 Ljubljana – Črnuče."
                                checked={isAgreed}
                                onChange={handleCheckboxChange}
                                required
                            />
                        </div>
                        <br />
                        <div className="form-row">
                            <label>Podpis</label>
                            <canvas
                                ref={canvasRef}
                                width={300}
                                height={150}
                                style={{
                                    border: '1px solid #000',
                                    cursor: 'crosshair',
                                }}
                            />
                            <Button onClick={() => signaturePad?.clear()}>Izbriši podpis</Button>
                        </div>
                    </Form>
                )}
            </Modal>

            {notification && (
                <ToastNotification
                    kind={notification.kind}
                    title={notification.title}
                    subtitle={notification.subtitle}
                    onClose={() => setNotification(null)}
                    timeout={5000}
                    style={{
                        maxWidth: '100%',
                        width: '100%',
                    }}
                />
            )}
        </>
    );
};

export default LeadButton;