import React, { useState, useEffect, useCallback } from 'react';
import {
    DataTable,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    Pagination,
    TableToolbar,
    TableToolbarContent,
    Button,
    DatePicker,
    DatePickerInput,
    Dropdown
} from '@carbon/react';
import PocketBase from 'pocketbase';
import debounce from 'lodash.debounce'; 
import { useAuth } from '../../AuthContext';


const ObiskiAdmin = () => {
    const { isAuthenticated, currentUserId, currentUserFullName, currentUserPhoneNum, currentUserEmail, pb, login, logout, isTestDatabase } = useAuth();

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [filteredData, setFilteredData] = useState([]);
    const [agents, setAgents] = useState([]); 

    const [selectedAgent, setSelectedAgent] = useState(null);
    const [datumFilter, setDatumFilter] = useState({ from: '', to: '' });
    const [selectedOdziv, setSelectedOdziv] = useState(null);

    const odzivOptions = [
        { id: 'DG', label: 'DG', value: 'DG' },
        { id: 'NŽ', label: 'NŽ', value: 'NŽ' },
        { id: 'NN', label: 'NN', value: 'NN' },
        { id: 'ŽI', label: 'ŽI', value: 'ŽI' },
        { id: 'ND', label: 'ND', value: 'ND' },
        { id: 'Prodano', label: 'Prodano', value: 'Prodano' },
    ];

    const fetchAgents = async () => {
        try {
            const agentList = await pb.collection('users').getFullList({
                filter: 'role="agent"', 
                sort: 'name', 
            });
            const agentOptions = agentList.map((agent) => ({
                id: agent.id,
                label: agent.name || 'Neimenovani agent', 
                value: agent.name,
            }));
            setAgents(agentOptions);
        } catch (error) {
            console.error('Napaka pri pridobivanju agentov:', error);
        }
    };

    const applyFilters = useCallback(
        debounce(async (currentFilters) => {
            setLoading(true);
            try {
                let filterQuery = '';

                if (currentFilters.agent) {
                    filterQuery += `agent.name = "${currentFilters.agent}"`;
                }

                if (currentFilters.datum.from || currentFilters.datum.to) {
                    if (filterQuery) filterQuery += ' && ';
                    const fromDate = currentFilters.datum.from
                        ? `"${currentFilters.datum.from} 00:00:00"`
                        : '1970-01-01 00:00:00';
                    const toDate = currentFilters.datum.to
                        ? `"${currentFilters.datum.to} 23:59:59"`
                        : '"2100-12-31 23:59:59"';
                    filterQuery += `datum >= ${fromDate} && datum <= ${toDate}`;
                }

                if (currentFilters.odziv) {
                    if (filterQuery) filterQuery += ' && ';
                    filterQuery += `odziv = "${currentFilters.odziv}"`;
                }

                const result = await pb.collection('obiski').getList(1, perPage, {
                    filter: filterQuery || undefined,
                    sort: '-created',
                    expand: 'agent',
                });

                if (result && result.items) {
                    setFilteredData(result.items);
                    setTotalItems(result.totalItems);
                    setPage(1);
                } else {
                    setFilteredData([]);
                    setTotalItems(0);
                }
            } catch (error) {
                console.error('Napaka pri pridobivanju filtriranih rezultatov:', error);
                setFilteredData([]);
                setTotalItems(0);
            } finally {
                setLoading(false);
            }
        }, 300),
        [perPage]
    );

    const fetchObiskiData = async () => {
        setLoading(true);
        try {
            const result = await pb.collection('obiski').getList(page, perPage, {
                sort: '-created',
                expand: 'agent',
            });
            setData(result.items);
            setFilteredData(result.items);
            setTotalItems(result.totalItems);
        } catch (error) {
            console.error('Napaka pri pridobivanju podatkov o obiskih:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchObiskiData();
        fetchAgents(); 
    }, [page, perPage]);

    const handleAgentFilterChange = ({ selectedItem }) => {
        setSelectedAgent(selectedItem);
        applyFilters({ agent: selectedItem?.value || '', datum: datumFilter, odziv: selectedOdziv?.value || '' });
    };

    const handleDatumFilterChange = (dates) => {
        const from = dates[0] ? dates[0].toLocaleDateString('en-CA') : '';
        const to = dates[1] ? dates[1].toLocaleDateString('en-CA') : '';
        setDatumFilter({ from, to });
        applyFilters({ agent: selectedAgent?.value || '', datum: { from, to }, odziv: selectedOdziv?.value || '' });
    };

    const handleOdzivFilterChange = ({ selectedItem }) => {
        setSelectedOdziv(selectedItem);
        applyFilters({ agent: selectedAgent?.value || '', datum: datumFilter, odziv: selectedItem?.value || '' });
    };

    const clearFilters = () => {
        setSelectedAgent(null);
        setDatumFilter({ from: '', to: '' });
        setSelectedOdziv(null);
        setFilteredData(data);
        setTotalItems(data.length);
    };

    const headers = [
        { key: 'agent', header: 'Agent' },
        { key: 'datum', header: 'Datum' },
        { key: 'odziv', header: 'Odziv' }
    ];

    const getAgentCell = (agent) => {
        if (!agent) return 'Neznan';
        const agentName = agent.name || 'Neimenovani agent';
        const avatarUrl = agent.avatar
            ? pb.baseUrl + `/api/files/_pb_users_auth_/${agent.id}/${agent.avatar}`
            : 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png';

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={avatarUrl}
                    alt={agentName}
                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginRight: '10px' }}
                />
                <span>{agentName}</span>
            </div>
        );
    };

    return (
        <TableContainer
            title="Zapisi o obiskih"
            description="Spremljajte vse zapise o obiskih"
        >
            <TableToolbar>
                <TableToolbarContent>
                    <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                        <Dropdown
                            id="agent-dropdown"
                            label="Izberite agenta"
                            items={agents}
                            itemToString={(item) => (item ? item.label : '')}
                            selectedItem={selectedAgent}
                            onChange={handleAgentFilterChange}
                            placeholder="Filtriraj po agentu"
                        />

                        <DatePicker
                            dateFormat="Y-MM-DD"
                            datePickerType="range"
                            onChange={handleDatumFilterChange}
                            placeholder="Filtriraj po datumu"
                        >
                            <DatePickerInput
                                id="datum-from"
                                placeholder="Od"
                                value={datumFilter.from}
                            />
                            <DatePickerInput
                                id="datum-to"
                                placeholder="Do"
                                value={datumFilter.to}
                            />
                        </DatePicker>

                        <Dropdown
                            id="odziv-dropdown"
                            label="Izberite odziv"
                            items={odzivOptions}
                            itemToString={(item) => (item ? item.label : '')}
                            selectedItem={selectedOdziv}
                            onChange={handleOdzivFilterChange}
                            placeholder="Filtriraj po odzivu"
                        />

                        <Button onClick={clearFilters}>Počisti filtre</Button>
                    </div>
                </TableToolbarContent>
            </TableToolbar>
            <DataTable
                rows={filteredData.map((record) => ({
                    id: record.id,
                    agent: getAgentCell(record.expand?.agent),
                    datum: new Date(record.datum).toLocaleDateString(),
                    odziv: record.odziv
                }))}
                headers={headers}
                isSortable
                loading={loading}
            >
                {({
                    rows,
                    headers,
                    getHeaderProps,
                    getRowProps,
                    getTableProps,
                }) => (
                    <Table {...getTableProps()}>
                        <TableHead>
                            <TableRow>
                                {headers.map((header) => (
                                    <TableHeader {...getHeaderProps({ header })} key={header.key}>
                                        {header.header}
                                    </TableHeader>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow {...getRowProps({ row })} key={row.id}>
                                    {row.cells.map((cell) => (
                                        <TableCell key={cell.id}>{cell.value}</TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
            </DataTable>
            <Pagination
                page={page}
                pageSize={perPage}
                pageSizes={[10, 20, 30, 50]}
                totalItems={totalItems}
                pageRangeText={(_current, total) => `od ${total} ${total === 1 ? 'stran' : 'strani'}`}
                itemRangeText={(min, max, total) => `${min}–${max} od ${total} zapisov`}
                itemsPerPageText="Zapisov na stran"
                backwardText="Prejšnja stran"
                forwardText="Naslednja stran"
                onChange={({ page, pageSize }) => {
                    setPage(page);
                    setPerPage(pageSize);
                }}
            />
        </TableContainer>
    );
};

export default ObiskiAdmin;