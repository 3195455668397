import React, { useEffect, useState } from 'react';
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  DatePicker,
  DatePickerInput,
  Button,
  ButtonSet
} from 'carbon-components-react';
import PocketBase from 'pocketbase';
import { useAuth } from '../../AuthContext';
import { config } from '../../config'; 

const AgentLeaderboard = () => {
  const {
    isAuthenticated,
    currentUserId,
    currentUserFullName,
    currentUserPhoneNum,
    currentUserEmail,
    pb,
    login,
    logout,
    isTestDatabase
  } = useAuth();
  const [agents, setAgents] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchAgentData = async () => {
    try {
      let filterString = '';

      if (startDate && endDate) {
        const prevDay = new Date(startDate);
        prevDay.setDate(prevDay.getDate() - 1);
        const startDateFormatted = `${prevDay.toISOString().split('T')[0]}T00:00:00Z`;

        // Add one day to the endDate to include the whole day
        const nextDay = new Date(endDate);
        nextDay.setDate(nextDay.getDate() + 1);
        const endDateFormatted = `${nextDay.toISOString().split('T')[0]}T00:00:00Z`;

        filterString = `created > "${startDateFormatted}" && created < "${endDateFormatted}"`;
      }

      const users = await pb.collection('users').getFullList();

      const orders = await pb.collection('narocilo').getFullList({
        filter: filterString,
      });

      const orderCountByAgent = {};
      orders.forEach(order => {
        const agentId = order.agent;
        if (agentId in orderCountByAgent) {
          orderCountByAgent[agentId]++;
        } else {
          orderCountByAgent[agentId] = 1;
        }
      });

      const agentsWithOrderCount = users.map(user => ({
        ...user,
        orderCount: orderCountByAgent[user.id] || 0
      }));

      agentsWithOrderCount.sort((a, b) => b.orderCount - a.orderCount);

      setAgents(agentsWithOrderCount);
    } catch (error) {
      console.error('Error fetching agent data:', error);
    }
  };

  useEffect(() => {
    fetchAgentData();
  }, []);

  const handleFetch = () => {
    fetchAgentData();
  };

  const handleStartDateChange = (selected) => {
    const date = selected[0];
    if (date) {
      setStartDate(date.toISOString().split('T')[0]);
    } else {
      setStartDate('');
    }
  };

  const handleEndDateChange = (selected) => {
    const date = selected[0];
    if (date) {
      setEndDate(date.toISOString().split('T')[0]);
    } else {
      setEndDate('');
    }
  };

  const handleToday = () => {
    const today = new Date();
    const formattedToday = today.toISOString().split('T')[0];

    setStartDate(formattedToday);
    setEndDate(formattedToday);
    fetchAgentData();
  };

  const handleThisWeek = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(today);
    const day = today.getDay();
    const diff = today.getDate() - day + (day === 0 ? -6 : 1);
    firstDayOfWeek.setDate(diff);

    const formattedFirstDay = firstDayOfWeek.toISOString().split('T')[0];
    const formattedToday = today.toISOString().split('T')[0];

    setStartDate(formattedFirstDay);
    setEndDate(formattedToday);
    fetchAgentData();
  };

  const handleThisMonth = () => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2);
    const formattedFirstDay = firstDayOfMonth.toISOString().split('T')[0];
    const formattedToday = today.toISOString().split('T')[0];

    setStartDate(formattedFirstDay);
    setEndDate(formattedToday);
    fetchAgentData();
  };

  const handleLastMonth = () => {
    const today = new Date();
    const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 2);
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const formattedFirstDay = firstDayLastMonth.toISOString().split('T')[0];
    const formattedLastDay = lastDayLastMonth.toISOString().split('T')[0];

    setStartDate(formattedFirstDay);
    setEndDate(formattedLastDay);
    fetchAgentData();
  };

  const headers = [
    { key: 'avatar', header: 'Avatar' },
    { key: 'name', header: 'Ime' },
    { key: 'orderCount', header: 'Naročila' },
  ];

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <ButtonSet>
          <Button kind="ghost" onClick={handleToday}>
            Danes
          </Button>
          <Button kind="ghost" onClick={handleThisWeek}>
            Teden
          </Button>
          <Button kind="ghost" onClick={handleThisMonth}>
            Mesec
          </Button>
          <Button kind="ghost" onClick={handleLastMonth}>
            Prejšnji Mesec
          </Button>
        </ButtonSet>
      </div>

      <div style={{ marginBottom: '20px', display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
        <DatePicker
          datePickerType="single"
          onChange={handleStartDateChange}
          dateFormat="Y-m-d"
          value={startDate ? [new Date(startDate)] : []}
        >
          <DatePickerInput
            id="start-date-picker"
            labelText="Od"
            placeholder="YYYY-MM-DD"
            value={startDate}
          />
        </DatePicker>
        <DatePicker
          datePickerType="single"
          onChange={handleEndDateChange}
          dateFormat="Y-m-d"
          value={endDate ? [new Date(endDate)] : []}
        >
          <DatePickerInput
            id="end-date-picker"
            labelText="Do"
            placeholder="YYYY-MM-DD"
            value={endDate}
          />
        </DatePicker>
        <Button onClick={handleFetch}>Pridobi</Button>
      </div>

      <DataTable
        rows={agents.map(agent => {
          const avatarUrl = agent.avatar
            ?  pb.baseUrl + `/api/files/_pb_users_auth_/${agent.id}/${agent.avatar}`
            : 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png';
          return {
            id: agent.id,
            avatar: avatarUrl,
            name: agent.name || agent.username,
            orderCount: agent.orderCount
          };
        })}
        headers={headers}
        render={({ rows, headers, getHeaderProps, getRowProps }) => (
          <Table>
            <TableHead>
              <TableRow>
                {headers.map(header => (
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.id} {...getRowProps({ row })}>
                  <TableCell>
                    <img
                      src={row.cells.find(cell => cell.info.header === 'avatar').value}
                      alt={row.cells.find(cell => cell.info.header === 'name').value}
                      style={{ width: '40px', height: '40px', borderRadius: '50%' }}
                    />
                  </TableCell>
                  <TableCell>{row.cells.find(cell => cell.info.header === 'name').value}</TableCell>
                  <TableCell>{row.cells.find(cell => cell.info.header === 'orderCount').value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
    </div>
  );
};

export default AgentLeaderboard;